import React from "react";
import { FaArrowLeft } from "react-icons/fa6";
import { USPS } from "../../../utils/constants";
import Button from "../../Button";

const QuoteService = ({ handleSubmit, data, setData, handleBack }) => {
  const calculatePrice = (_price, perPrice, weight, label) => {
    if (label?.toUpperCase() === "KG") {
      weight = weight * 2.20462;
    }

    let price;
    if (weight <= 5) {
      price = _price;
    } else {
      price = _price + (weight - 5) * perPrice;
    }
    return price.toFixed(2);
  };

  return (
    <form onSubmit={handleSubmit}>
      <p className="font-semibold mb-3">Pick a service</p>
      <div>
        {USPS?.map((itm, idx) => (
          <div
            key={idx}
            onClick={() =>
              setData(prev => ({
                ...prev,
                labelType: itm?.value,
              }))
            }
            className="flex justify-between items-center border-[1px] border-[#D8D8D8] rounded-lg w-full p-[14px] mb-3 cursor-pointer"
          >
            <div className="flex items-center gap-2">
              <p
                className={`min-w-3 min-h-3 rounded-full ${
                  itm?.value === data?.labelType ? "bg-primary" : "border-[1px] border-[#0000004D]"
                }`}
              ></p>
              <span>{itm?.name}</span>
            </div>
            <span className="font-semibold opacity-90">
              $
              {calculatePrice(
                itm.value === "express" ? 7 : 6,
                itm.value === "express" ? 1 : 0.9,
                Number(data?.weight),
                data?.weightType,
              )}
            </span>
          </div>
        ))}
        <div
          onClick={() =>
            setData(prev => ({
              ...prev,
              labelType: "fedex",
              service_type: "",
            }))
          }
          className="flex justify-between items-center border-[1px] border-[#D8D8D8] rounded-lg w-full p-[14px] mb-3 cursor-pointer"
        >
          <div className="flex items-center gap-2">
            <p
              className={`min-w-3 min-h-3 rounded-full ${
                "fedex" === data?.labelType ? "bg-primary" : "border-[1px] border-[#0000004D]"
              }`}
            ></p>
            <span>FedEx Priority Overnight®</span>
          </div>
          <span className="font-semibold opacity-90">
            ${calculatePrice(18, 1, Number(data?.weight), data?.weightType)}
          </span>
        </div>
        {/* {UPS?.map((itm, idx) => (
          <div
            key={idx}
            className="bg-[#f0f0f0] flex justify-between items-center border-[1px] border-[#D8D8D8] rounded-lg w-full p-[14px] mb-3"
          >
            <div className="flex w-full items-center gap-2 justify-between">
              <div className="flex items-center gap-2">
                <p className={`min-w-3 min-h-3 rounded-full border-[1px] border-[#0000004D]`}></p>
                <span>{itm?.name}</span>
              </div>
              <p className="text-sm text-[#3C81E9] px-3 py-1 font-semibold rounded-[100px] bg-[#e2e2e2]">
                Coming Soon
              </p>
            </div>
          </div>
        ))} */}
      </div>
      <br />
      <br />
      <Button
        type="submit"
        width="full"
      >
        Next Step
      </Button>
      <p
        onClick={handleBack}
        className="p-4 flex items-center justify-center gap-2 cursor-pointer text-[#666666]"
      >
        <FaArrowLeft fill="#666666" />
        Back
      </p>
    </form>
  );
};

export default QuoteService;
