import React from "react";

const WeightSelect = ({ data, weight, weightType, handleChange }) => {
  return (
    <div className="flex items-center gap-2 border-[1px] border-[#D8D8D8] rounded-lg w-full p-[14px]">
      <input
        type="number"
        name={weight}
        value={data[weight] || ""}
        onChange={handleChange}
        className="border-0 outline-none w-[calc(100%-_-50px)]"
        placeholder={`10 ${data[weightType]}`}
        required
      />
      <select
        className="border-0 outline-none"
        name={weightType}
        value={data[weightType]}
        onChange={handleChange}
      >
        <option value="LB">LB</option>
        <option value="KG">KG</option>
      </select>
    </div>
  );
};

export default WeightSelect;
