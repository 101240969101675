import React from "react";
import { Link, useLocation } from "react-router-dom";
import { REACT_APP_KEY_NAME } from "../utils/constants";
import Cookies from "js-cookie";
import { LINKS } from "../utils/links";

const Header = () => {
  const location = useLocation();
  const isLoggedIn = Cookies.get(REACT_APP_KEY_NAME) && localStorage.getItem(REACT_APP_KEY_NAME);

  const isActive = path => {
    if (location.pathname === path) return "text-primary";
    return "";
  };

  const logout = () => {
    Cookies.remove(REACT_APP_KEY_NAME);
    localStorage.removeItem(REACT_APP_KEY_NAME);
    window.location.href = "/";
  };

  return (
    <header className="max-w-[1367px] mx-auto px-4 xl:px-6 w-full flex gap-3 justify-between items-center py-3 md:py-4">
      <Link to="/">
        <img
          className="max-w-[170px] max-sm:w-[120px]"
          src="/assets/logoBlack.svg"
          alt="Shipitor Logo"
        />
      </Link>
      <div className="flex items-center gap-2 md:gap-3">
        <div>
          {LINKS.map((itm, idx) => (
            <Link
              to={itm.to}
              key={idx}
              className={`${isActive(
                itm.to,
              )} max-sm:hidden font-[500] text-sm xl:text-xl hover:text-primary p-2 sm:p-4 hover:transition`}
            >
              {itm.title}
            </Link>
          ))}
          {isLoggedIn && (
            <>
              <Link
                to="/dashboard"
                className="font-[500] text-sm xl:text-xl hover:text-primary p-2 sm:p-4 hover:transition"
              >
                Dashboard
              </Link>
              <Link
                to="/billing"
                className="font-[500] text-sm xl:text-xl hover:text-primary p-2 sm:p-4 hover:transition"
              >
                Billing
              </Link>
              <a
                href="#"
                onClick={logout}
                className="font-[700] text-sm xl:text-xl text-primary p-2 sm:p-4"
              >
                Logout
              </a>
            </>
          )}
        </div>
        {!isLoggedIn && (
          <div className="flex gap-4 max-sm:gap-2">
            <Link
              to="/login"
              className="rounded-[6px] text-primary bg-white max-sm:text-sm text-base text-center px-3 py-2 md:px-6 md:py-3 font-[500] hover:transition border-[1px] border-primary 2xl:text-xl hover:bg-primary hover:text-white"
              style={{ boxShadow: "0px 14px 14px 0px #3c81e914" }}
            >
              Login
            </Link>
            <Link
              to="/signup"
              className="rounded-[6px] bg-primary text-white max-sm:text-sm text-base text-center px-3 py-2 md:px-6 md:py-3 font-[500] hover:transition border-[1px] border-primary 2xl:text-xl hover:bg-white hover:text-primary"
              style={{ boxShadow: "0px 14px 14px 0px #3C81E924" }}
            >
              Register
            </Link>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
