import { useEffect } from "react";
import { Route, useLocation } from "react-router-dom";
import "./App.css";
import Protected from "./protectedRoute";
import Public from "./publicRoutes";
import { PROTECTED_ROUTES, PUBLIC_ROUTES, ROUTES } from "./utils/routes";
import Register from "./pages/register";

function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      {ROUTES.map((route, index) => (
        <Route
          exact
          {...route}
          key={index}
        />
      ))}
      {PUBLIC_ROUTES.map((route, index) => (
        <Public
          exact
          {...route}
          key={index}
        />
      ))}
      {PROTECTED_ROUTES.map((route, index) => (
        <Protected
          exact
          {...route}
          key={index}
        />
      ))}
      <Public
        exact
        path="/r/:referral"
        component={Register}
      />
    </>
  );
}

export default App;
