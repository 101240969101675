import React from "react";

const data = [
  {
    src: "/assets/how/how1.png",
    desc: "Tell us where you're shipping your package from and what's the destination",
  },
  {
    src: "/assets/how/how2.png",
    desc: "Provide the weight and size of your package so we can determine the best deals",
  },
  {
    src: "/assets/how/how3.png",
    desc: "Choose a courier that's right for you and pay for your shipping online",
  },
  {
    src: "/assets/how/how4.png",
    desc: "Attach your shipping label and drop off your package or have it collected",
  },
];

const HowWork = () => {
  return (
    <div className="bg-secondary w-full">
      <div className="w-full py-7 sm:py-14 max-w-[1367px] mx-auto px-4 xl:px-6">
        <h2 className="text-3xl text-center sm:text-[40px] font-[600] mb-7">How We Work</h2>
        <div className="main-slider overflow-x-scroll flex w-full pb-2">
          {data?.map((itm, idx) => (
            <div
              className="w-[25%] min-w-[305px] px-2"
              key={idx + 1}
            >
              <img
                src={itm.src}
                className="w-full"
                alt={itm.desc}
              />
              <p className="mt-2 text-sec_text">{itm.desc}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HowWork;
