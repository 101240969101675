import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Api from "../api/api";
import Button from "../components/Button";
import Loader from "../components/Loader";
import Auth from "../layout/Auth";
import CustomModal from "../layout/Modal";

const Login = () => {
  const history = useHistory();
  const [isDisable, setIsDisable] = useState(false);
  const [email, setEmail] = useState("");
  const [show, setShow] = useState(false);

  const submitHandle = async e => {
    e.preventDefault();
    setIsDisable(true);

    let _data = { email };

    let _referral = localStorage.getItem("referral");
    if (_referral) _data["referal"] = _referral;

    const res = await Api.login(_data);
    if (res.status === 200) {
      localStorage.removeItem("referral");
      if (res.data.verified) {
        setShow(true);
        await new Promise(resolve => setTimeout(resolve, 5000));
        setShow(false);
        history.push(`/`);
      } else {
        history.push(`/payment?email=${email}`);
      }
    } else if (res?.status === 403) {
      history.push(`/payment?email=${email}`);
    }

    setIsDisable(false);
  };

  useEffect(() => {
    return () => {
      setEmail("");
      setIsDisable(false);
    };
  }, []);

  return (
    <Auth
      isTextShow={false}
      submitHandle={submitHandle}
    >
      <div className="mt-4 mb-3">
        <label className="text-[#333333] font-medium">Email*</label>
        <input
          type="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          className="mt-1 w-full border border-[#D8D8D8] rounded-md p-[10px_16px] text-base"
          required
        />
      </div>
      <Button
        width="full"
        type="submit"
        style={{ marginTop: "1.5rem" }}
        disabled={isDisable}
      >
        {!isDisable ? "Login to your account" : <Loader />}
      </Button>
      {show && (
        <CustomModal
          title="Verify Sign In"
          isClose={true}
          onHide={() => [setShow(false), history.push("/")]}
        >
          <p className="text-center max-w-[350px] font-[500] text-lg">
            A login link was sent to you. You can check your email and click on the link or button.
            Check spam or trash as some providers may have placed it there.
          </p>
        </CustomModal>
      )}
    </Auth>
  );
};

export default Login;
