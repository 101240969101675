import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import QuoteInfo from "../components/GuestQuote/Domestic/QuoteInfo";
import QuoteService from "../components/GuestQuote/Domestic/QuoteService";
import SenderDetails from "../components/Checkout/Domestic/Sender";
import RecipientDetails from "../components/Checkout/Domestic/Recipient";
import {
  REACT_APP_KEY_NAME,
  REACT_APP_STORAGE_DATA,
  REACT_APP_STORAGE_TYPE,
  USPS,
  USPS_payload,
  fedex_payload,
  initialDomestic,
} from "../utils/constants";

const QuoteDomestic = () => {
  const history = useHistory();
  const location = useLocation();
  const isLoggedIn = Cookies.get(REACT_APP_KEY_NAME) && localStorage.getItem(REACT_APP_KEY_NAME);
  const [data, setData] = useState({ ...initialDomestic });
  const [show, setShow] = useState({
    step1: true,
    step2: false,
    step3: false,
    step4: false,
  });

  const handleInputChange = (key, e) => {
    const { name, value } = e.target;
    if (name === "zipcode" && value) {
      if (!/^[0-9]+$/.test(value)) return;
    }
    setData(prev => ({ ...prev, [key]: { ...prev[key], [name]: value } }));
  };

  const handleStepsShow = key => {
    const prev = { step1: false, step2: false, step3: false, step4: false };
    setShow({ ...prev, [key]: true });
  };

  const handleSenderDetail = e => {
    e.preventDefault();
    setData(prev => ({
      ...prev,
      sender: {
        ...prev?.sender,
        phone_number: data?.sender?.phone_number?.slice(2),
      },
    }));

    handleStepsShow("step4");
  };

  const handleCheckout = async e => {
    e.preventDefault();

    let _data = { ...data };
    _data["receiver"]["phone_number"] = _data?.receiver?.phone_number?.slice(2);

    let __data = {};
    if (USPS.find(itm => itm.value === data.labelType)) {
      __data = USPS_payload(_data);
      localStorage.setItem(REACT_APP_STORAGE_DATA, JSON.stringify(__data));
    } else if (data.labelType === "fedex") {
      __data = fedex_payload(data);
      localStorage.setItem(
        REACT_APP_STORAGE_DATA,
        JSON.stringify({ ...__data, labelType: "fedex" }),
      );
    }
    localStorage.setItem(REACT_APP_STORAGE_TYPE, "domestic");
    history.push(isLoggedIn ? "/dashboard" : "/signup");
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const weight = params.get("weight");
    const type = params.get("type");

    if (weight && type) {
      setData(prev => ({ ...prev, weight: Number(weight), weightType: type }));
    }
  }, [location.pathname]);

  return (
    <div className={"flex h-[100vh] flex-row-reverse"}>
      <div className="max-[991px]:hidden w-[60%] bg-secondary">
        <div className="flex w-full items-center justify-center h-[100%]">
          <img
            src="/assets/auth.svg"
            alt="Shipitor"
          />
        </div>
      </div>
      <div className="max-[991px]:w-full w-[40%] h-full overflow-y-auto">
        <div className="flex w-full items-center justify-center h-[100%] p-4 sm:p-8">
          <div className="w-full max-w-[500px] h-full">
            <Link
              to="/"
              className="block w-fit"
            >
              <img
                className="2xl:w-[200px]"
                src="/assets/logoBlack.svg"
                alt="Shipitor Logo"
              />
            </Link>
            <br />
            <div className="mt-6 relative">
              {!show.step1 && (
                <div className="absolute left-0 top-[-20px] bg-[#D9D9D9] h-1 w-[150px]">
                  <div
                    className={`${show.step2 ? "w-[33%]" : show.step3 ? "w-[66%]" : "w-[90%]"} h-full bg-primary`}
                  ></div>
                </div>
              )}
              {show.step1 && (
                <QuoteInfo
                  data={data}
                  setData={setData}
                  handleSubmit={() => handleStepsShow("step2")}
                />
              )}
              {show.step2 && (
                <QuoteService
                  data={data}
                  setData={setData}
                  handleSubmit={() =>
                    data?.labelType === "fedex"
                      ? handleStepsShow("step4")
                      : handleStepsShow("step3")
                  }
                  handleBack={() => handleStepsShow("step1")}
                />
              )}
              {show.step3 && (
                <SenderDetails
                  isGuest={true}
                  data={data?.sender}
                  setData={setData}
                  handleChange={e => handleInputChange("sender", e)}
                  handleSubmit={handleSenderDetail}
                  handleBack={() => handleStepsShow("step2")}
                />
              )}
              {show.step4 && (
                <RecipientDetails
                  isGuest={true}
                  labelType={data?.labelType}
                  data={data?.receiver}
                  setData={setData}
                  handleChange={e => handleInputChange("receiver", e)}
                  handleSubmit={handleCheckout}
                  handleBack={() =>
                    data?.labelType === "fedex"
                      ? handleStepsShow("step2")
                      : handleStepsShow("step3")
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuoteDomestic;
