import { Link, useLocation } from "react-router-dom";

const Auth = ({ isTextShow = true, subTitle, submitHandle, children }) => {
  const location = useLocation();

  return (
    <div className={`flex h-[100vh] ${location.pathname === "/payment" ? "flex-row-reverse" : ""}`}>
      <div className="max-[991px]:hidden w-[60%] bg-secondary">
        <div className="flex w-full items-center justify-center h-[100%]">
          <img
            src="/assets/auth.svg"
            alt="Shipitor Login"
          />
        </div>
      </div>
      <div className="max-[991px]:w-full w-[40%] h-full overflow-y-auto">
        <div className="flex w-full items-center justify-center h-[100%] p-4 sm:p-8">
          {location.pathname !== "/payment" ? (
            <form
              onSubmit={submitHandle}
              className="w-full max-w-[500px]"
            >
              <Link to="/">
                <img
                  className="2xl:w-[300px]"
                  src="/assets/logoBlack.svg"
                  alt="Shipitor Logo"
                />
              </Link>
              {isTextShow && (
                <>
                  <h1 className="bg-[#3C81E91A] text-primary px-4 text-sm w-fit py-2 mt-5 font-semibold rounded-[50px]">
                    Add a credit card and get a domestic shipping label!
                  </h1>
                  <p className="text-sec_text mb-3 mt-6 2xl:my-3">{subTitle}</p>
                </>
              )}
              {children}
            </form>
          ) : (
            <div>
              <Link to="/">
                <img
                  className="2xl:w-[300px]"
                  src="/assets/logoBlack.svg"
                  alt="Shipitor Logo"
                />
              </Link>
              {isTextShow && (
                <>
                  <h1 className="bg-[#3C81E91A] text-primary px-4 text-sm w-fit py-2 mt-5 font-semibold rounded-[50px]">
                    Add a credit card and get a domestic shipping label!
                  </h1>
                  <p className="text-sec_text mb-3 mt-6 2xl:my-3">{subTitle}</p>
                </>
              )}
              {children}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Auth;
