import React from "react";
import { LOADER_OVERRIDE } from "../utils/constants";
import { ClipLoader } from "react-spinners";

const Loader = () => {
  return (
    <ClipLoader
      color="#FFFFFF"
      loading={true}
      cssOverride={LOADER_OVERRIDE}
      size={25}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  );
};

export default Loader;
