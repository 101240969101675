import React from "react";
import Button from "../../Button";
import { FaArrowLeft, FaTrash } from "react-icons/fa6";

const ItemsDetail = ({
  isGuest = false,
  handleSubmit,
  data,
  setData,
  handleChange,
  handleBack,
}) => {
  return (
    <form
      className={!isGuest && "mt-4 min-w-[320px] w-[80vw] lg:w-[991px]"}
      onSubmit={handleSubmit}
    >
      <div className="flex justify-end mb-4">
        <p
          className="text-primary cursor-pointer font-medium"
          onClick={() =>
            setData(prev => ({
              ...prev,
              items: [
                ...prev?.items,
                {
                  itemDescription: "",
                  HSCode: "",
                  quantity: 0,
                  UOM: "",
                  itemWeight: 0,
                  itemValue: 0,
                  totalValue: 0,
                },
              ],
            }))
          }
        >
          + Add Multiple Items
        </p>
      </div>

      <div className="p-2 bg-[#3C81E91A] border border-primary rounded-lg text-sm font-medium mb-4">
        By providing your full name electronically within the designated fields, you acknowledge and
        authorize us to utilize this data for the purpose of generating documents particularly for
        customs clearance. Your electronic signature serves as your legal binding signature on the
        generated commercial invoice and other customs documents. If you have more than one item,
        repeat this step for each item.
      </div>

      <div>
        {data?.items?.map((item, idx) => (
          <>
            {idx > 0 && <div className="h-[2px] bg-[#00000010] mb-4 mt-6"></div>}
            <div className="mb-3 flex max-md:flex-wrap items-center gap-3 md:gap-4">
              <div className="w-full md:flex-1">
                <label className="text-lg font-[500] mb-2 block">What are you shipping?</label>
                <input
                  name="itemDescription"
                  value={item?.itemDescription}
                  onChange={e => handleChange(e, idx)}
                  className="border-[1px] border-[#D8D8D8] rounded-lg w-full p-[14px]"
                />
              </div>
              <div className="w-full md:flex-1">
                <label className="text-lg font-[500] mb-2 block">HS Code</label>
                <input
                  name="HSCode"
                  value={item?.HSCode}
                  onChange={e => handleChange(e, idx)}
                  className="border-[1px] border-[#D8D8D8] rounded-lg w-full p-[14px]"
                />
              </div>
            </div>
            <div className="mb-3 flex max-md:flex-wrap items-center gap-3 md:gap-4">
              <div className="w-full md:flex-1">
                <label className="text-lg font-[500] mb-2 block">Quantity</label>
                <input
                  type="number"
                  name="quantity"
                  value={item?.quantity}
                  onChange={e => handleChange(e, idx)}
                  className="border-[1px] border-[#D8D8D8] rounded-lg w-full p-[14px]"
                  min={0}
                  required
                />
              </div>
              <div className="w-full md:flex-1">
                <label className="text-lg font-[500] mb-2 block">Units Of Measurement</label>
                <input
                  name="UOM"
                  value={item?.UOM}
                  onChange={e => handleChange(e, idx)}
                  className="border-[1px] border-[#D8D8D8] rounded-lg w-full p-[14px]"
                  required
                />
              </div>
            </div>
            <div className="mb-3 flex max-md:flex-wrap items-center gap-3 md:gap-4">
              <div className="w-full md:flex-1">
                <label className="text-lg font-[500] mb-2 block">Item Weight</label>
                <input
                  type="number"
                  name="itemWeight"
                  value={item?.itemWeight}
                  onChange={e => handleChange(e, idx)}
                  className="border-[1px] border-[#D8D8D8] rounded-lg w-full p-[14px]"
                  placeholder={`10 ${item?.WeightType}`}
                  min={0}
                  required
                />
              </div>
              <div className="w-full md:flex-1">
                <label className="text-lg font-[500] mb-2 block">Item Value (in USD)</label>
                <input
                  type="number"
                  name="itemValue"
                  value={item?.itemValue}
                  onChange={e => handleChange(e, idx)}
                  className="border-[1px] border-[#D8D8D8] rounded-lg w-full p-[14px]"
                  min={0}
                  required
                />
              </div>
            </div>
            <div className="mb-2 flex max-md:flex-wrap items-start gap-3 md:gap-4">
              <div className="w-full md:w-[49%]">
                <label className="text-lg font-[500] mb-2 block">Total Value (in USD)</label>
                <input
                  readOnly
                  value={(
                    parseFloat(item?.itemValue || 0) * parseFloat(item?.quantity || 0)
                  ).toFixed(2)}
                  className="border-[1px] border-[#D8D8D8] rounded-lg w-full p-[14px]"
                />
              </div>
            </div>
            {idx > 0 && (
              <div className="flex justify-end">
                <FaTrash
                  fill="#FF4747"
                  className="cursor-pointer"
                  size={20}
                  onClick={() =>
                    setData(prev => ({
                      ...prev,
                      items: prev?.items?.filter((_, id) => id !== idx),
                    }))
                  }
                />
              </div>
            )}
          </>
        ))}
      </div>

      <br />
      <div className="h-[2px] bg-[#00000027] mb-5"></div>

      <div className="mb-8 flex max-md:flex-wrap items-start gap-3 md:gap-4">
        <div className="w-full md:w-[49%]">
          <label className="text-lg font-[500] mb-2 block">Signature</label>
          <input
            name="signature"
            value={data?.signature}
            onChange={e => setData(prev => ({ ...prev, signature: e.target.value }))}
            className="border-[1px] border-[#D8D8D8] rounded-lg w-full p-[14px]"
            placeholder="Jon Doe"
            required
          />
        </div>
      </div>

      <Button
        type="submit"
        width="full"
      >
        Submit
      </Button>
      <p
        onClick={handleBack}
        className="p-4 flex items-center justify-center gap-2 cursor-pointer text-[#666666]"
      >
        <FaArrowLeft fill="#666666" />
        Back
      </p>
    </form>
  );
};

export default ItemsDetail;
