export const LINKS = [
  {
    to: "/",
    title: "Home",
  },
  // {
  //   to: "/pricing",
  //   title: "Pricing",
  // }
];
