import React, { useState } from "react";
import Button from "../../Button";
import { STATES } from "../../../utils/states";
import PhoneInput from "react-phone-number-input/input";
import { FaArrowLeft } from "react-icons/fa6";

const SenderDetails = ({
  isGuest = false,
  handleSubmit,
  data,
  setData,
  handleChange,
  handleBack,
}) => {
  const [is16, setIs16] = useState(14);
  return (
    <form
      className={!isGuest && "mt-4 min-w-[320px] w-fit sm:w-[500px] max-w-[500px]"}
      onSubmit={handleSubmit}
    >
      {isGuest && <p className="font-semibold mb-4">Sender Details </p>}
      <div className="mb-[10px]">
        <label className="text-lg font-[500] mb-2 block">Name / Company</label>
        <input
          type="text"
          name="name"
          value={data?.name}
          onChange={handleChange}
          className="border-[1px] border-[#D8D8D8] rounded-lg w-full p-[14px]"
          placeholder="Jow Doe"
        />
      </div>
      <div className="mb-[10px]">
        <label className="text-lg font-[500] mb-2 block">Street Address</label>
        <input
          type="text"
          name="street"
          value={data?.street}
          onChange={handleChange}
          className="border-[1px] border-[#D8D8D8] rounded-lg w-full p-[14px]"
          placeholder="123 Street Address"
          required
        />
      </div>
      <div className="mb-[10px]">
        <label className="text-lg font-[500] mb-2 block">Address (optional)</label>
        <input
          type="text"
          name="street2"
          value={data?.street2}
          onChange={handleChange}
          className="border-[1px] border-[#D8D8D8] rounded-lg w-full p-[14px]"
        />
      </div>
      <div className="mb-[10px]">
        <label className="text-lg font-[500] mb-2 block">City</label>
        <input
          type="text"
          name="city"
          value={data?.city}
          onChange={handleChange}
          placeholder="Los Angeles"
          className="border-[1px] border-[#D8D8D8] rounded-lg w-full p-[14px]"
          required
        />
      </div>
      <div className="mb-[10px] flex items-center gap-4">
        <div className="flex-1">
          <label className="text-lg font-[500] mb-2 block">State</label>
          <select
            type="text"
            name="state"
            value={data?.state}
            onChange={handleChange}
            className="border-[1px] border-[#D8D8D8] rounded-lg w-full p-[14px]"
            required
          >
            {STATES?.map(itm => (
              <option value={itm.value}>{itm.label}</option>
            ))}
          </select>
        </div>
        <div className="flex-1">
          <label className="text-lg font-[500] mb-2 block">Zip Code</label>
          <input
            type="text"
            name="zipcode"
            value={data?.zipcode}
            onChange={handleChange}
            className="border-[1px] border-[#D8D8D8] rounded-lg w-full p-[14px]"
            placeholder="90001"
            minLength={5}
            maxLength={5}
            required
          />
        </div>
      </div>
      <div className="mb-8">
        <label className="text-lg font-[500] mb-2 block">Phone</label>
        <PhoneInput
          country="US"
          className="border-[1px] border-[#D8D8D8] rounded-lg w-full p-[14px]"
          onInput={e => {
            if (/^1 /.test(e.target.value)) {
              setIs16(16);
            } else {
              setIs16(14);
            }
          }}
          onChange={e => {
            setData(prev => ({
              ...prev,
              sender: {
                ...prev?.sender,
                phone_number: e,
              },
            }));
          }}
          value={data?.phone_number}
          maxLength={is16}
          required
        />
      </div>
      <Button
        type="submit"
        width="full"
      >
        Next Step
      </Button>
      <p
        onClick={handleBack}
        className="p-4 flex items-center justify-center gap-2 cursor-pointer text-[#666666]"
      >
        <FaArrowLeft fill="#666666" />
        Back
      </p>
    </form>
  );
};

export default SenderDetails;
