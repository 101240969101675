import "react-phone-input-2/lib/style.css";
import React from "react";
import Button from "../../Button";
import PhoneInput from "react-phone-input-2";
import { FaArrowLeft } from "react-icons/fa6";
import WeightSelect from "../../../common/WeightSelect";
import { countries } from "../../../utils/constants";

const RecipientDetails = ({
  isGuest = false,
  handleSubmit,
  data,
  setData,
  handleChange,
  handleBack,
}) => {
  return (
    <form
      className={!isGuest && "mt-4 min-w-[320px] w-[80vw] lg:w-[991px]"}
      onSubmit={handleSubmit}
    >
      <div className="p-2 bg-[#3C81E91A] border border-primary rounded-lg text-sm font-medium mb-4">
        Please be advised that Shipitor acts as both an exporter and handles returns for goods
        shipped through our services. As an exporter, Shipitor facilitates the shipment process,
        including provide help for customs clearance and documentation.
      </div>

      <div className="mb-3 flex max-md:flex-wrap items-center gap-3 md:gap-4">
        <div className="w-full md:flex-1">
          <label className="text-lg font-[500] mb-2 block">Shipping From</label>
          <select
            name="shippingFrom"
            value={data?.shippingFrom}
            onChange={handleChange}
            className="border-[1px] border-[#D8D8D8] rounded-lg w-full p-[14px]"
            required
          >
            {countries?.map(itm => (
              <option
                key={itm.value}
                value={itm.value}
              >
                {itm.label}
              </option>
            ))}
          </select>
        </div>
        <div className="w-full md:flex-1">
          <div className="flex max-md:flex-wrap items-center gap-3 md:gap-4">
            <div className="w-full md:flex-1">
              <label className="text-lg font-[500] mb-2 block">Zip Code</label>
              <input
                name="shippingFromZIP"
                value={data?.shippingFromZIP}
                onChange={handleChange}
                className="border-[1px] border-[#D8D8D8] rounded-lg w-full p-[14px]"
                placeholder="90001"
                required
              />
            </div>
            <div className="w-full md:flex-1">
              <label className="text-lg font-[500] mb-2 block">Package Weight</label>
              <WeightSelect
                data={data}
                weight={"PackageWeight"}
                weightType={"WeightType"}
                handleChange={handleChange}
              />
            </div>
          </div>
        </div>
      </div>

      <br />
      <h3 className="text-2xl font-semibold mb-5">Recipient details</h3>

      <div className="mb-3 flex max-md:flex-wrap items-center gap-3 md:gap-4">
        <div className="w-full md:flex-1">
          <label className="text-lg font-[500] mb-2 block">Name</label>
          <input
            name="Name"
            value={data?.recipientDetails?.Name}
            onChange={e => handleChange(e, "recipientDetails")}
            className="border-[1px] border-[#D8D8D8] rounded-lg w-full p-[14px]"
          />
        </div>
        <div className="w-full md:flex-1">
          <label className="text-lg font-[500] mb-2 block">Company</label>
          <input
            name="Company"
            value={data?.recipientDetails?.Company}
            onChange={e => handleChange(e, "recipientDetails")}
            className="border-[1px] border-[#D8D8D8] rounded-lg w-full p-[14px]"
          />
        </div>
      </div>
      <div className="mb-3 flex max-md:flex-wrap items-center gap-3 md:gap-4">
        <div className="w-full md:flex-1">
          <label className="text-lg font-[500] mb-2 block">Address</label>
          <input
            name="address"
            value={data?.recipientDetails?.address}
            onChange={e => handleChange(e, "recipientDetails")}
            className="border-[1px] border-[#D8D8D8] rounded-lg w-full p-[14px]"
            required
          />
        </div>
        <div className="w-full md:flex-1">
          <label className="text-lg font-[500] mb-2 block">Address (optional)</label>
          <input
            name="address2"
            value={data?.recipientDetails?.address2}
            onChange={e => handleChange(e, "recipientDetails")}
            className="border-[1px] border-[#D8D8D8] rounded-lg w-full p-[14px]"
          />
        </div>
      </div>
      <div className="mb-3 flex max-md:flex-wrap items-center gap-3 md:gap-4">
        <div className="w-full md:flex-1">
          <label className="text-lg font-[500] mb-2 block">State</label>
          <input
            name="state"
            value={data?.recipientDetails?.state}
            onChange={e => handleChange(e, "recipientDetails")}
            className="border-[1px] border-[#D8D8D8] rounded-lg w-full p-[14px]"
            required
          />
        </div>
        <div className="w-full md:flex-1">
          <label className="text-lg font-[500] mb-2 block">City</label>
          <input
            name="city"
            value={data?.recipientDetails?.city}
            onChange={e => handleChange(e, "recipientDetails")}
            className="border-[1px] border-[#D8D8D8] rounded-lg w-full p-[14px]"
            required
          />
        </div>
      </div>

      <div className="mb-3 flex max-md:flex-wrap items-center gap-3 md:gap-4">
        <div className="w-full md:flex-1">
          <label className="text-lg font-[500] mb-2 block">Zip Code</label>
          <input
            name="zipCode"
            value={data?.recipientDetails?.zipCode}
            onChange={e => handleChange(e, "recipientDetails")}
            className="border-[1px] border-[#D8D8D8] rounded-lg w-full p-[14px]"
            placeholder="90001"
            required
          />
        </div>
        <div className="w-full md:flex-1">
          <label className="text-lg font-[500] mb-2 block">Country</label>
          <select
            name="country"
            value={data?.recipientDetails?.country}
            onChange={e => handleChange(e, "recipientDetails")}
            className="border-[1px] border-[#D8D8D8] rounded-lg w-full p-[14px]"
            required
          >
            {countries?.map(itm => (
              <option
                key={itm.value}
                value={itm.value}
              >
                {itm.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="mb-8 flex max-md:flex-wrap items-center gap-3 md:gap-4">
        <div className="w-full md:flex-1">
          <label className="text-lg font-[500] mb-2 block">Phone</label>
          <PhoneInput
            country={data?.recipientDetails?.country?.toLowerCase()}
            className="w-full [&>.form-control]:!h-[54px] [&>.form-control]:!w-full [&>.form-control]:!rounded-lg [&>.form-control]:!border-[#d8d8d8d]"
            onChange={e =>
              setData(prev => ({
                ...prev,
                recipientDetails: { ...prev?.recipientDetails, phone: e },
              }))
            }
            value={data?.recipientDetails?.phone}
            required
          />
        </div>
        <div className="w-full md:flex-1">
          <label className="text-lg font-[500] mb-2 block">TAX ID (optional but recommended)</label>
          <input
            name="taxID"
            value={data?.recipientDetails?.taxID}
            onChange={e => handleChange(e, "recipientDetails")}
            className="border-[1px] border-[#D8D8D8] rounded-lg w-full p-[14px]"
          />
        </div>
      </div>

      <Button
        type="submit"
        width="full"
      >
        Next Step
      </Button>
      <p
        onClick={handleBack}
        className="p-4 flex items-center justify-center gap-2 cursor-pointer text-[#666666]"
      >
        <FaArrowLeft fill="#666666" />
        Back
      </p>
    </form>
  );
};

export default RecipientDetails;
