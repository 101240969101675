import React from "react";

const About = () => {
  return (
    <div className="w-full py-7 sm:py-14 max-w-[1367px] mx-auto">
      <div className="flex w-full items-center flex-wrap-reverse max-md:gap-y-4">
        <div className="w-[50%] px-7 max-md:w-[100%] max-sm:px-4">
          <img
            className="w-full"
            src="/assets/about.png"
            alt="About us"
          />
        </div>
        <div className="w-[50%] px-7 max-md:w-[100%] max-sm:px-4">
          <h2 className="text-3xl sm:text-[40px] font-[600] mb-5">About Us</h2>
          <p className="text-sec_text">
            Welcome to Shipitor – your trusted partner in seamless shipping solutions. At Shipitor,
            we're more than just a shipping service; we're the driving force behind your business's
            logistics success. Our mission is to simplify and elevate your shipping experience,
            providing efficient, reliable, and customizable solutions tailored to your unique needs.
            Founded on principles of innovation and reliability, Shipitor is committed to redefining
            the way you navigate the world of logistics. Backed by a team of dedicated
            professionals, we strive to build lasting relationships with our clients, offering a
            user-friendly platform designed for transparency, efficiency, and peace of mind. Choose
            Shipitor for a shipping experience that goes beyond the transaction. We're here to
            support your business's journey with reliability, innovation, and a commitment to your
            success. Welcome to the Shipitor family – where your shipping needs meet simplicity.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
