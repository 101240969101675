import React from "react";
import WeightSelect from "../../../common/WeightSelect";
import { FaArrowLeft } from "react-icons/fa6";
import Button from "../../Button";
import { useHistory } from "react-router-dom";

const QuoteInfo = ({ data, setData, handleSubmit }) => {
  const history = useHistory();

  const handleChange = e => {
    const { value, name } = e.target;
    setData(prev => ({ ...prev, [name]: value }));
  };

  return (
    <form onSubmit={handleSubmit}>
      <h3 className="text-xl 2xl:text-2xl font-semibold">Get a Quote</h3>
      <p className="font-semibold mt-1 mb-4">
        We need some additional information to help you with your quote.
      </p>
      <div className="flex items-center w-full gap-4 mb-8 max-md:flex-wrap">
        <div className="w-full md:flex-1">
          <label className="text-lg font-[500] mb-2 block">Pick up Zip Code</label>
          <input
            value={data?.sender?.zipcode}
            onChange={e =>
              setData(prev => ({
                ...prev,
                sender: {
                  ...prev?.sender,
                  zipcode: e.target.value,
                },
              }))
            }
            className="border-[1px] border-[#D8D8D8] rounded-lg w-full p-[14px]"
            placeholder="Pickup zip code"
            required
          />
        </div>
        <div className="w-full md:flex-1">
          <label className="text-lg font-[500] mb-2 block">Delivery Zip Code</label>
          <input
            value={data?.receiver?.zipcode}
            onChange={e =>
              setData(prev => ({
                ...prev,
                receiver: {
                  ...prev?.receiver,
                  zipcode: e.target.value,
                },
              }))
            }
            className="border-[1px] border-[#D8D8D8] rounded-lg w-full p-[14px]"
            placeholder="Delivery zip code"
            required
          />
        </div>
      </div>
      <div className="lg:max-w-[70%] mx-auto">
        <input
          name="referal"
          value={data?.referal || ""}
          onChange={handleChange}
          className="mb-3 border-[1px] border-[#D8D8D8] rounded-lg w-full p-[14px]"
          placeholder="Promo / Referral Code (optional)"
        />
        <WeightSelect
          data={data}
          weight="weight"
          weightType="weightType"
          handleChange={handleChange}
        />
      </div>
      <br />
      <br />
      <Button
        type="submit"
        width="full"
      >
        Next Step
      </Button>
      <p
        onClick={() => history.push("/")}
        className="p-4 flex items-center justify-center gap-2 cursor-pointer text-[#666666]"
      >
        <FaArrowLeft fill="#666666" />
        Back
      </p>
    </form>
  );
};

export default QuoteInfo;
