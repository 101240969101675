import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { LOADER_OVERRIDE } from "../utils/constants";
import { ClipLoader } from "react-spinners";
import Api from "../api/api";

const VerifyLogin = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const email = query.get("email");
  const token = query.get("token");

  const verifyLogin = async (email, token) => {
    try {
      await Api.verifyLogin({ email, token });
    } catch (error) {}
  };

  useEffect(() => {
    if (email && token) {
      verifyLogin(email, token);
    }
  }, [email, token]);

  return (
    <div className="w-full h-[100vh] grid place-items-center">
      <ClipLoader
        color="#3c81e9"
        loading={true}
        cssOverride={LOADER_OVERRIDE}
        size={25}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};

export default VerifyLogin;
