import Header from "../components/Header";
import Footer from "../components/Footer";
import About from "../components/Home/About";
import Packages from "../components/Home/Packages";
import HowWork from "../components/Home/HowWork";
import Hero from "../components/Home/Hero";

const Home = () => {
  return (
    <>
      <Header />
      <main>
        <Hero />
        <About />
        <HowWork />
        <Packages />
      </main>
      <Footer />
    </>
  );
};

export default Home;
