import React from "react";
import Button from "../../Button";
import { FaArrowLeft } from "react-icons/fa6";

const PaymentDetails = ({ isGuest = false, data, handleSubmit, handleBack }) => {
  return (
    <form
      className={!isGuest && "mt-4 min-w-[320px] w-[80vw] lg:w-[991px]"}
      onSubmit={handleSubmit}
    >
      <div className="p-2 bg-[#3C81E91A] border border-primary rounded-lg text-sm font-medium mb-4">
        We received your shipment request. Using the provided information, we may calculate the
        following cost, excluding import tariffs and customs taxes. Our services are for commercial
        use only. You will be charged on Friday via an invoice including the shipment charge and all
        customs and duties. Your shipping label may take one business day to generate. Please email
        hello@shipitor.com if you want your recipient to reimburse us for customs duties.
      </div>
      <br />
      <br />
      <div className="w-fit py-3 px-5 mx-auto rounded-lg border border-[#D8D8D8]">
        <h3 className="text-center font-semibold text-[#666666] text-xl">
          FedEx International Priority® Express
        </h3>
        <br />
        <p className="text-center">
          <span className="mr-4 text-3xl font-bold">${data?.ourCharge || 0}</span>
          <span className="line-through text-3xl font-bold text-[#666666]">
            ${data?.totalNetCharge || 0}
          </span>
        </p>
        <br />
      </div>
      <br />
      <br />

      <Button
        type="submit"
        width="full"
      >
        Create Shipment
      </Button>
      <p
        onClick={handleBack}
        className="p-4 flex items-center justify-center gap-2 cursor-pointer text-[#666666]"
      >
        <FaArrowLeft fill="#666666" />
        Back
      </p>
    </form>
  );
};

export default PaymentDetails;
