import React from "react";
import { useHistory } from "react-router-dom";
import Button from "../Button";

const Packages = () => {
  const history = useHistory();
  return (
    <div
      className="w-full h-[350px] flex items-center justify-center flex-col gap-7 px-4 xl:px-6"
      style={{
        background: "url(/assets/packagesBG.svg)",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <h2 className="text-3xl sm:text-[40px] text-primary font-[600] text-center">
        Ready To Ship a Package?
      </h2>
      <Button onClick={() => history.push("/signup")}>Get A Quote</Button>
    </div>
  );
};

export default Packages;
