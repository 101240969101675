import React from "react";
import WeightSelect from "../../../common/WeightSelect";
import { FaArrowLeft } from "react-icons/fa6";
import Button from "../../Button";
import { useHistory } from "react-router-dom";
import { countries } from "../../../utils/constants";

const QuoteInfo = ({ data, setData, handleChange, handleSubmit }) => {
  const history = useHistory();

  const _handleChange = e => {
    const { value, name } = e.target;
    setData(prev => ({ ...prev, [name]: value }));
  };

  return (
    <form onSubmit={handleSubmit}>
      <h3 className="text-xl 2xl:text-2xl font-semibold">Get a Quote</h3>
      <p className="font-semibold mt-1 mb-4">
        We need some additional information to help you with your quote.
      </p>
      <div className="w-full flex gap-4 max-md:flex-wrap">
        <div className="w-full md:flex-1">
          <div className="w-full md:flex-1 font-semibold">Sending from</div>
          <div className="my-3">
            <label className="text-lg font-[500] mb-2 block">Country</label>
            <input
              value={countries.find(itm => itm.value === data?.shippingFrom)?.label}
              readOnly
              disabled
              className="border-[1px] opacity-80 border-[#D8D8D8] rounded-lg w-full p-[14px]"
            />
          </div>
          <div className="mb-6">
            <label className="text-lg font-[500] mb-2 block">Zip Code</label>
            <input
              name="shippingFromZIP"
              value={data?.shippingFromZIP}
              onChange={handleChange}
              className="border-[1px] border-[#D8D8D8] rounded-lg w-full p-[14px]"
              placeholder="Pickup zip code"
              required
            />
          </div>
        </div>

        <div className="w-full md:flex-1">
          <div className="w-full md:flex-1 font-semibold">Sending to</div>
          <div className="my-3">
            <label className="text-lg font-[500] mb-2 block">Country</label>
            <input
              value={countries.find(itm => itm.value === data?.recipientDetails?.country)?.label}
              readOnly
              disabled
              className="border-[1px] opacity-80 border-[#D8D8D8] rounded-lg w-full p-[14px]"
            />
          </div>
          <div className="mb-6">
            <label className="text-lg font-[500] mb-2 block">Zip Code</label>
            <input
              name="zipCode"
              value={data?.recipientDetails?.zipCode}
              onChange={e => handleChange(e, "recipientDetails")}
              className="border-[1px] border-[#D8D8D8] rounded-lg w-full p-[14px]"
              placeholder="Delivery zip code"
              required
            />
          </div>
        </div>
      </div>

      <div className="lg:max-w-[70%] mx-auto">
        <input
          name="referal"
          value={data?.referal || ""}
          onChange={handleChange}
          className="mb-3 border-[1px] border-[#D8D8D8] rounded-lg w-full p-[14px]"
          placeholder="Promo / Referral Code (optional)"
        />
        <WeightSelect
          data={data}
          weight="PackageWeight"
          weightType="WeightType"
          handleChange={_handleChange}
        />
      </div>
      <br />
      <br />
      <Button
        type="submit"
        width="full"
      >
        Next Step
      </Button>
      <p
        onClick={() => history.push("/")}
        className="p-4 flex items-center justify-center gap-2 cursor-pointer text-[#666666]"
      >
        <FaArrowLeft fill="#666666" />
        Back
      </p>
    </form>
  );
};

export default QuoteInfo;
