import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";

const CustomModal = ({ children, title, onHide, isClose }) => {
  const modalRef = useRef();
  useEffect(() => {
    let handler = e => {
      if (!modalRef.current.contains(e.target)) {
        onHide();
      }
    };

    document.addEventListener("mousedown", handler);
    return () => document.removeEventListener("mousedown", handler);
  }, [onHide]);

  return createPortal(
    <>
      <div
        className="fixed top-0 left-0 right-0 bottom-0 z-[900] bg-[#0000004D]"
        style={{ backdropFilter: "blur(2px)" }}
      ></div>
      <div
        ref={modalRef}
        className="model-cont max-h-[90vh] overflow-y-scroll fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[1000] w-fit h-auto p-4 md:p-7 bg-[#ffffff] rounded-lg"
      >
        <div className="flex items-center justify-between pb-2 mb-2 font-[500]">
          <p className="text-xl md:text-2xl font-[600]">{title}</p>
          {isClose && (
            <img
              onClick={onHide}
              src="/assets/icons/close.svg"
              alt="Close"
              className="cursor-pointer"
            />
          )}
        </div>
        <div className="custom_modal_body relative">{children}</div>
      </div>
    </>,
    document.getElementById("portal"),
  );
};

export default CustomModal;
