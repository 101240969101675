import "react-phone-number-input/style.css";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import PhoneInput from "react-phone-number-input/input";
import Auth from "../layout/Auth";
import Button from "../components/Button";
import Api from "../api/api";
import CustomModal from "../layout/Modal";
import Loader from "../components/Loader";

const initialState = {
  email: "",
  name: "",
  phone_number: "",
  expected_shipments: "",
  company_name: "",
  referal: "",
};

const Register = () => {
  const history = useHistory();
  const location = useLocation();
  const { referral } = useParams();
  const [isDisable, setIsDisable] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState({ ...initialState });
  const [show, setShow] = useState(false);

  const inputHandle = e => {
    const { name, value } = e.target;
    setData(prev => ({ ...prev, [name]: value }));
  };

  const submitHandle = async e => {
    e.preventDefault();

    setIsDisable(true);

    const res = await Api.login({
      ...data,
      phone_number: data?.phone_number?.slice(2),
    });
    if (res.status === 200) {
      localStorage.removeItem("referral");
      if (res.data.verified) {
        setMessage(res?.data?.message || "");
        setShow(true);
        await new Promise(resolve => setTimeout(resolve, 5000));
        setShow(false);
        history.push(`/`);
      } else {
        history.push(`/payment?email=${data.email}`);
      }
    } else if (res?.status === 403) {
      history.push(`/payment?email=${data.email}`);
    }
    setIsDisable(false);
  };

  useEffect(() => {
    if (referral) {
      localStorage.setItem("referral", referral);
      setData(prev => ({ ...prev, referal: referral }));
    }
  }, [location.pathname]);

  useEffect(() => {
    let _referral = localStorage.getItem("referral");
    if (_referral) setData(prev => ({ ...prev, referal: _referral }));

    return () => {
      setData({ ...initialState });
      setIsDisable(false);
    };
  }, []);

  return (
    <Auth
      subTitle="We just need a bit of information before you start shipping! "
      submitHandle={submitHandle}
    >
      <div className="mb-3">
        <label className="text-[#333333] font-medium">Email*</label>
        <input
          type="email"
          name="email"
          value={data?.email}
          onChange={inputHandle}
          className="mt-1 w-full border border-[#D8D8D8] rounded-md p-[10px_16px] text-base"
          required
        />
      </div>
      <div className="mb-3">
        <label className="text-[#333333] font-medium">Name*</label>
        <input
          type="text"
          name="name"
          value={data?.name}
          onChange={inputHandle}
          className="mt-1 w-full border border-[#D8D8D8] rounded-md p-[10px_16px] text-base"
          required
        />
      </div>
      <div className="mb-3">
        <label className="text-[#333333] font-medium">Company*</label>
        <input
          type="text"
          name="company_name"
          value={data?.company_name}
          onChange={inputHandle}
          className="mt-1 w-full border border-[#D8D8D8] rounded-md p-[10px_16px] text-base"
          required
        />
      </div>
      <div className="mb-3">
        <label className="text-[#333333] font-medium">Phone Number*</label>
        <PhoneInput
          country="US"
          className="mt-1 w-full border border-[#D8D8D8] rounded-md p-[10px_16px] text-base"
          onChange={e => setData(prev => ({ ...prev, phone_number: e }))}
          value={data?.phone_number}
          maxLength={14}
          required
        />
      </div>
      <div className="mb-3">
        <label className="text-[#33333380] font-medium">
          How many packages do you ship per month on average?
        </label>
        <input
          type="number"
          name="expected_shipments"
          value={data?.expected_shipments}
          placeholder="Estimated Monthly Packages"
          onChange={inputHandle}
          className="mt-1 w-full border border-[#D8D8D8] rounded-md p-[10px_16px] text-base"
          required
        />
      </div>
      <div className="mb-3">
        <input
          name="referal"
          value={data?.referal}
          placeholder="Promo/Referral Code (Optional)"
          onChange={inputHandle}
          className="mt-1 w-full border border-[#D8D8D8] rounded-md p-[10px_16px] text-base"
        />
      </div>

      <Button
        width="full"
        type="submit"
        style={{ marginTop: "1.5rem" }}
        disabled={isDisable}
      >
        {!isDisable ? "Sign up" : <Loader />}
      </Button>
      {message && show && (
        <CustomModal
          title="Verify Sign Up"
          isClose={true}
          onHide={() => [setShow(false), history.push("/")]}
        >
          <p className="text-center max-w-[350px] text-lg font-[500]">{message}</p>
        </CustomModal>
      )}
    </Auth>
  );
};

export default Register;
