import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import Button from "../Button";
import { REACT_APP_KEY_NAME, REACT_APP_STORAGE_TYPE, countries } from "../../utils/constants";

const Hero = () => {
  const history = useHistory();
  const isLoggedIn = Cookies.get(REACT_APP_KEY_NAME) && localStorage.getItem(REACT_APP_KEY_NAME);
  const [data, setData] = useState({ from: "", to: "", weight: "", type: "LB" });

  const handleChange = e => {
    const { name, value } = e.target;
    setData(prev => ({ ...prev, [name]: value }));
  };

  const handleGuestQuote = e => {
    e.preventDefault();
    if ((data.from === "US" && data.to === "US") || (data.from === "CA" && data.to === "CA")) {
      if (isLoggedIn) {
        localStorage.setItem(REACT_APP_STORAGE_TYPE, "domestic");
        history.push(`/checkout`);
      } else {
        history.push(`/quote-domestic?weight=${data.weight}&type=${data.type}`);
      }
    } else {
      if (isLoggedIn) {
        localStorage.setItem(REACT_APP_STORAGE_TYPE, "intl");
        history.push(`/checkout`);
      } else {
        history.push(`/quote-intl?${new URLSearchParams(data).toString()}`);
      }
    }
  };

  return (
    <div className="bg-secondary w-full mt-[-96px] h-[90vh] flex items-center justify-center">
      <div className="w-full relative py-7 sm:py-14 max-w-[1367px] mx-auto px-4 xl:px-6">
        <h2 className="text-3xl max-w-[1024px] mx-auto text-center sm:text-[48px] leading-[2.4rem] md:leading-[3.5rem] font-[600] mb-6">
          The Shipper’s choice for faster quality service at a better price
        </h2>
        {/* <div className="flex justify-center">
          <Button onClick={() => history.push("/checkout")}>Create a shipment</Button>
        </div> */}
        <form
          onSubmit={handleGuestQuote}
          className="max-w-[820px] mx-auto flex max-md:flex-wrap bg-white items-center border border-[#D8D8D8] rounded-lg w-full"
          style={{
            boxShadow: "0px 24px 60px 0px #062F7D0D",
          }}
        >
          <select
            name="from"
            value={data?.from}
            onChange={handleChange}
            className="w-full md:flex-1 p-2 max-md:border-b md:border-r border-[#D8D8D8]"
            required
          >
            <option value="">-- From --</option>
            {countries?.map(itm => (
              <option
                key={itm.value}
                value={itm.value}
              >
                {itm.label}
              </option>
            ))}
          </select>
          <select
            name="to"
            value={data?.to}
            onChange={handleChange}
            className="w-full md:flex-1 p-2 max-md:border-b md:border-r border-[#D8D8D8]"
            required
          >
            <option value="">-- To --</option>
            {countries?.map(itm => (
              <option
                key={itm.value}
                value={itm.value}
              >
                {itm.label}
              </option>
            ))}
          </select>
          <div className="w-full md:flex-1 md:w-fit max-md:mb-1">
            <div className="flex items-center gap-2 p-2 w-full">
              <input
                type="number"
                name="weight"
                value={data["weight"]}
                onChange={handleChange}
                className="border-0 outline-none md:max-w-[140px]"
                placeholder="Weight"
                required
              />
              <select
                className="border-0 outline-none max-w-[60px]"
                name="type"
                value={data["type"]}
                onChange={handleChange}
                required
              >
                <option value="LB">LB</option>
                <option value="KG">KG</option>
              </select>
            </div>
          </div>
          <Button
            type="submit"
            className="max-md:w-full md:min-w-fit"
          >
            Get A Quote
          </Button>
        </form>
        <img
          className="absolute top-[-10%] left-[-3%] max-xl:hidden"
          src="/assets/hero/Icon1.png"
          alt="Shipitor"
        />
        <img
          className="absolute top-[80%] left-[10%] w-[120px] max-sm:w-[100px] max-sm:top-[100%]"
          src="/assets/hero/Icon2.png"
          alt="Shipitor"
        />
        <img
          className="absolute top-[120%] left-[30%] max-md:hidden"
          src="/assets/hero/Icon3.png"
          alt="Shipitor"
        />
        <img
          className="absolute top-[120%] right-[30%] max-md:hidden"
          src="/assets/hero/Icon4.png"
          alt="Shipitor"
        />
        <img
          className="absolute top-[80%] right-[10%] max-sm:w-[100px] max-sm:top-[130%]"
          src="/assets/hero/Icon5.png"
          alt="Shipitor"
        />
        <img
          className="absolute top-[-10%] right-[-3%] max-xl:hidden"
          src="/assets/hero/Icon6.png"
          alt="Shipitor"
        />
      </div>
    </div>
  );
};

export default Hero;
