import React, { useCallback, useEffect, useMemo, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Button from "../components/Button";
import { useHistory } from "react-router-dom";
import Api from "../api/api";
import { REACT_APP_STORAGE_DATA, REACT_APP_STORAGE_TYPE } from "../utils/constants";

const UPSMethods = ["2nd Day Air", "3 Day Select", "Next Day Air", "Next Day Air Early"];

const HEAD = [
  "Weight",
  "Sender Info",
  "Recipient Info",
  // "Track",
  "Tracking",
  "Label",
];

const Dashboard = () => {
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [data, setData] = useState(null);

  const labelShipments = useMemo(() => {
    if (!search.trim()) {
      return data?.labelShipments;
    }

    return data?.labelShipments?.filter(
      item =>
        item?.fromName.toLowerCase().includes(search.toLowerCase()) ||
        item?.toName.toLowerCase().includes(search.toLowerCase()) ||
        item?.tracking.toLowerCase().includes(search.toLowerCase()),
    );
  }, [data, search]);

  const fedexShipments = useMemo(() => {
    if (!search.trim()) {
      return data?.fedexShipments;
    }

    return data?.fedexShipments?.filter(
      item =>
        item?.name.toLowerCase().includes(search.toLowerCase()) ||
        item?.address.toLowerCase().includes(search.toLowerCase()) ||
        item?.phone_number.toLowerCase().includes(search.toLowerCase()),
    );
  }, [data, search]);

  const internationalShipments = useMemo(() => {
    if (!search.trim()) {
      return data?.internationalShipments;
    }

    return data?.internationalShipments?.filter(
      item =>
        item?.recipientDetails.Name.toLowerCase().includes(search.toLowerCase()) ||
        item?.recipientDetails.address.toLowerCase().includes(search.toLowerCase()) ||
        item?.recipientDetails.phone.toLowerCase().includes(search.toLowerCase()),
    );
  }, [data, search]);

  const getData = useCallback(async () => {
    const res = await Api.getShipments();
    setData(res.data || null);
  }, [setData]);

  useEffect(() => {
    getData();
  }, []);

  const createDomesticLabel = async _data => {
    try {
      await Api.createUSPSShipment(_data);
    } catch (error) {
      console.log(error);
    } finally {
      getData();
      localStorage.removeItem(REACT_APP_STORAGE_TYPE);
      localStorage.removeItem(REACT_APP_STORAGE_DATA);
    }
  };

  const createDomesticFedexLabel = async _data => {
    try {
      const { labelType, ...data } = _data;
      await Api.createFedexShipment(data);
    } catch (error) {
      console.log(error);
    } finally {
      getData();
      localStorage.removeItem(REACT_APP_STORAGE_TYPE);
      localStorage.removeItem(REACT_APP_STORAGE_DATA);
    }
  };

  const createIntlLabel = async ({ quoteId }) => {
    if (!quoteId) return;
    try {
      await Api.acceptIntlQuote({ quoteId });
    } catch (error) {
      console.log(error);
    } finally {
      getData();
      localStorage.removeItem(REACT_APP_STORAGE_TYPE);
      localStorage.removeItem(REACT_APP_STORAGE_DATA);
    }
  };

  useEffect(() => {
    const type = localStorage.getItem(REACT_APP_STORAGE_TYPE);
    const _data = localStorage.getItem(REACT_APP_STORAGE_DATA)
      ? JSON.parse(localStorage.getItem(REACT_APP_STORAGE_DATA))
      : {};

    if (type && _data) {
      if (type === "domestic") {
        if (_data?.labelType === "fedex") {
          createDomesticFedexLabel(_data);
        } else {
          createDomesticLabel(_data);
        }
      } else if (type === "intl") {
        createIntlLabel(_data);
      }
    }
  }, []);

  return (
    <>
      <Header />
      <main className="w-full py-7 sm:py-14 max-w-[1367px] mx-auto px-4 xl:px-6 min-h-[calc(100vh_-_82px_-_440px)] md:min-h-[calc(100vh_-_94px_-_300px)]">
        <div className="flex w-full items-center flex-wrap justify-between gap-4 mb-5">
          <h2 className="text-3xl sm:text-[40px] font-[600]">All Shipments</h2>
          <div className="flex items-center flex-wrap-reverse gap-4">
            <Button onClick={() => history.push("/checkout")}>+ Create label</Button>
            <div className="relative">
              <input
                type="text"
                value={search}
                onChange={e => setSearch(e.target.value)}
                className="border-[1px] border-[#0000004D] rounded-md pl-3 pr-7 py-3"
                placeholder="Search Parcel"
              />
              <img
                src="/assets/icons/search.svg"
                className="absolute top-[10px] right-[14px]"
                alt="Search icon"
              />
            </div>
          </div>
        </div>

        <h3 className="font-semibold text-xl mb-2">USPS Shipments</h3>
        <div className="relative overflow-x-auto">
          <table className="w-full text-base text-left">
            <thead className="bg-[#F9FAFC]">
              <tr>
                {HEAD?.map((itm, idx) => (
                  <th
                    key={idx + 1}
                    scope="col"
                    className="px-6 py-4 text-[#8F95B2]"
                  >
                    {itm}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {labelShipments?.map(itm => (
                <tr
                  className="border-b"
                  key={itm?._id}
                >
                  <th
                    scope="row"
                    className="px-4 md:px-6py-4 text-[#081735]"
                  >
                    {itm?.weight} LB
                  </th>
                  <td className="px-4 md:px-6 py-4">
                    <p className="font-[600]">{itm?.fromName}</p>
                    <span className="text-sm text-sec_text">{itm?.fromPhone}</span>
                  </td>
                  <td className="px-4 md:px-6 py-4">
                    <p className="font-[600]">{itm?.toName}</p>
                    <span className="text-sm text-sec_text">{itm?.toPhone}</span>
                  </td>
                  {/* <td className="px-4 md:px-6 py-4">
                    {itm?.tracking && (
                      <a
                        href={`https://www.aftership.com/track/ups/${itm?.tracking_number}`}
                        className="bg-[#F3F4F6] rounded-xl px-[6px] py-[10px] flex items-center justify-center gap-2"
                      >
                        <img
                          src="/assets/icons/track.svg"
                          alt="Track"
                        />
                        <span className="font-[500]">Track</span>
                      </a>
                    )}
                  </td> */}
                  <td className="px-4 md:px-6 py-4">
                    <p className="font-[600]">{itm?.tracking}</p>
                  </td>
                  <td className="px-4 md:px-6 py-4">
                    {itm?.OrderId && (
                      <a
                        href={
                          !UPSMethods.includes(itm?.labelType)
                            ? `${process.env.REACT_APP_API_URL}/order/download/usps/${itm.OrderId}`
                            : `${process.env.REACT_APP_API_URL}/order/download/ups/${itm.OrderId}`
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        className="rounded-full bg-[#3C81E91A] flex items-center justify-center gap-2 px-4 py-3"
                      >
                        <img
                          src="/assets/icons/download.svg"
                          alt="Download"
                        />
                        <span className="text-primary font-[600]">Download Label</span>
                      </a>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <br />
        <br />

        <h3 className="font-semibold text-xl mb-2">Fedex Shipments</h3>
        <div className="relative overflow-x-auto">
          <table className="w-full text-base text-left">
            <thead className="bg-[#F9FAFC]">
              <tr>
                {["Weight", "Recipient Info", "Status", "Tracking", "Label"]?.map((itm, idx) => (
                  <th
                    key={idx + 1}
                    scope="col"
                    className="px-6 py-4 text-[#8F95B2]"
                  >
                    {itm}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {fedexShipments?.map(itm => (
                <tr
                  className="border-b"
                  key={itm?._id}
                >
                  <th
                    scope="row"
                    className="px-4 md:px-6py-4 text-[#081735]"
                  >
                    {itm?.weight} LB
                  </th>

                  <td className="px-4 md:px-6 py-4">
                    <p className="font-[600]">{itm?.name}</p>
                    <span className="text-sm text-sec_text">{itm?.phone_number}</span>
                  </td>

                  <td className="px-4 md:px-6 py-4">
                    {itm?.status && (
                      <p
                        className={`${itm.status === "pending" ? "bg-[#fffed8] text-[#878305]" : "bg-[#d2ffd6] text-[#058710]"} w-fit rounded-[50px] text-sm text-center px-3 py-[3px] font-medium`}
                      >
                        {itm.status}
                      </p>
                    )}
                  </td>
                  <td className="px-4 md:px-6 py-4 capitalize">{itm?.tracking}</td>
                  <td className="px-4 md:px-6 py-4">
                    {itm.status !== "pending" && itm?.OrderId && (
                      <a
                        href={`${process.env.REACT_APP_API_URL}/order/download/fedex/${itm.OrderId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="w-fit rounded-full bg-[#3C81E91A] flex items-center justify-center gap-2 px-4 py-3"
                      >
                        <img
                          src="/assets/icons/download.svg"
                          alt="Download"
                        />
                        <span className="text-primary font-[600]">Download Label</span>
                      </a>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <br />
        <br />

        <h3 className="font-semibold text-xl mb-2">International Shipments</h3>
        <div className="relative overflow-x-auto">
          <table className="w-full text-base text-left">
            <thead className="bg-[#F9FAFC]">
              <tr>
                {["Weight", "Recipient Info", "Shipping From", "Quote", "Tracking", "URL"]?.map(
                  (itm, idx) => (
                    <th
                      key={idx + 1}
                      scope="col"
                      className="px-6 py-4 text-[#8F95B2]"
                    >
                      {itm}
                    </th>
                  ),
                )}
              </tr>
            </thead>
            <tbody>
              {internationalShipments?.map(itm => (
                <tr
                  className="border-b"
                  key={itm?._id}
                >
                  <th
                    scope="row"
                    className="px-4 md:px-6py-4 text-[#081735]"
                  >
                    {itm?.PackageWeight} {itm?.WeightType}
                  </th>

                  <td className="px-4 md:px-6 py-4">
                    <p className="font-[600]">{itm?.recipientDetails?.Name}</p>
                    <span className="text-sm text-sec_text">{itm?.recipientDetails?.phone}</span>
                  </td>
                  <td className="px-4 md:px-6 py-4">
                    <p className="font-[600]">{itm?.shippingFrom}</p>
                    <span className="text-sm text-sec_text">{itm?.shippingFromZIP}</span>
                  </td>

                  <td className="px-4 md:px-6 py-4">
                    <p className="font-[600] text-base">
                      {itm?.quote?.ourCharge}{" "}
                      <span className="line-through opacity-80 text-sm">
                        {itm?.quote?.totalNetCharge}
                      </span>{" "}
                      {itm?.quote?.currency}
                    </p>
                  </td>

                  <td className="px-4 md:px-6 py-4 capitalize font-medium">{itm?.tracking}</td>
                  <td className="px-4 md:px-6 py-4">
                    {itm.url !== "pending" && !itm?.pending ? (
                      <a
                        href={`${itm.url}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="w-fit rounded-full bg-[#3C81E91A] flex items-center justify-center gap-2 px-4 py-3"
                      >
                        <img
                          src="/assets/icons/download.svg"
                          alt="Download"
                        />
                        <span className="text-primary font-[600]">Download</span>
                      </a>
                    ) : (
                      <span className="capitalize font-medium">{itm.url}</span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Dashboard;
