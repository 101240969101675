import Footer from "../components/Footer";
import Header from "../components/Header";

export default function Privacy() {
  return (
    <>
      <Header />
      <main className="w-full py-7 sm:py-14 max-w-[1367px] mx-auto px-4 xl:px-6">
        <div className="w-full">
          <h1 className="text-lg font-[700] mb-2">Privacy Policy</h1>
          <p className="text-lg font-[700] mb-8">Date of Last Revision: February 20, 2024</p>

          {/*  */}
          <div>
            <h2 className="text-lg font-[700] mb-7">I. Introduction</h2>
            <p className="pl-3 underline font-medium">1.1 Overview</p>
            <p className="mb-6">
              The present Privacy Policy sets forth the terms upon which REECARD LLC, a California
              limited liability company ("
              <strong>Shipitor</strong>", "<strong>we</strong>", "<strong>us</strong>" or "
              <strong>our</strong>" and other similar terms) collects, uses and handles personal
              information provided on or in connection with Shipitor’s services and/or{" "}
              <a
                className="font-semibold"
                href="www.shipitor.com"
              >
                www.shipitor.com
              </a>
              and its corresponding mobile and web applications (collectively, the “Website”). For
              reference purposes, the shipping solutions, services and/or products offered by
              Shipitor shall be collectively, the “Services” and each, individually, a “Service”.
              <br />
              <br />
              The present Privacy Policy describes how Shipitor collects, uses, shares, retains and
              protects the personal information of users of our shipping services.
              <br />
              <br />
              <span className="mb-6 uppercase">
                PLEASE READ THIS PRIVACY POLICY BEFORE ACCESSING OR USING THE WEBSITE AND OUR
                SERVICES TO UNDERSTAND OUR PRACTICES REGARDING YOUR DATA.
              </span>
            </p>

            <p className="pl-3 underline font-medium">1.2 Scope</p>
            <p className="mb-6">
              The present Privacy Policy (the “<strong>Policy</strong>”) applies to personal data
              processed by Shipitor through the usage of our Website and our services. <br />
              <br />
              The present Policy shall not apply to third party services you may interact with or be
              directed to via our Website. The present Privacy Policy has been prepared based upon
              privacy and data protection laws applicable in the United States. This Privacy Policy
              applies solely to personal and non-personal data submitted and collected in connection
              with the Website and our Services, unless otherwise indicated.
            </p>
            <p className="pl-3 underline font-medium">1.3 Revisions</p>
            <p className="mb-7">
              We may amend or modify this Policy at any time at our discretion. The revised Policy
              shall be applicable to all personal data secured or transmitted through the Website
              and our Services once the revised Privacy Policy is published on the Website. <br />
              <br />
              We may but are under no obligation to notify you of any modifications to the Policy in
              advance by way of email. Please note, however, that substantive changes made to this
              Policy shall be communicated in advance through our Services or by email.
              <br />
              <br />
              We encourage all users of our Website to periodically review this Policy to check for
              updates and revisions.
            </p>
          </div>

          {/*  */}
          <div>
            <h2 className="text-lg font-[700] mb-5">II. Types of Data We Collect</h2>
            <p className="mb-6">
              Personal data refers to information relating to an identifiable individual. Such data
              may include but is not limited to your name, email address and telephone number.{" "}
              <br /> Non-personal data refers information that cannot be used to personally identify
              a specific individual. Non-personal data may include, without limitation, anonymous
              usage data, demographic information, preferences selected and analytical data
              generated based on usage.
            </p>
            <p className="pl-3 underline font-medium">
              2.1 Personal and Non-Personal Data Collected
            </p>
            <p className="mb-6">
              We may obtain and collect both personal and non-personal data provided through or when
              using the Website and our Services. We undertake to only collect personal data that is
              willingly and voluntarily provided in connection with our Services. <br />
              <br /> We may collect non-personal data based on your interactions with the Website
              and usage of our Services. As such, we may automatically collect certain usage data
              when you use our Services. Such data may include shipping details, transaction data,
              localization data, session logs, device information, analytics data and other usage
              related information. <br />
              <br /> We may collect indirect identifiers, such as your IP address and location, when
              you access and use the Services. Indirect identifiers are anonymous, and cannot allow
              us to identify you as an individual.
            </p>
            <p className="pl-3 underline font-medium">2.2 Registration Data</p>
            <p className="mb-6">
              You acknowledge that we require certain data to set up and manage your account for
              purposes of our Services. When registering an account, you may be asked to provide
              personal data, such as your first and last name, phone number, email and company name.
              You acknowledge that by submitting personal data to create an account on the Website,
              we may collect the data provided and use it for the purposes set forth in this Policy.{" "}
              <br />
              <br /> For account management purposes, we collect your name, email address, phone
              number, payment method and details, tax ID numbers and similar data.
            </p>
            <p className="pl-3 underline font-medium">2.3 Shipment Data</p>
            <p className="mb-6">
              When using our Services, you may need to provide details about shipments. Such details
              may include, without limitation, addresses, contents, declared value and other
              relevant details. We shall use the data provided for purposes of processing the
              shipment and ensuring optimal functioning.
            </p>
            <p className="pl-3 underline font-medium">2.4 Communications</p>
            <p className="mb-6">
              When you communicate with us via mail, email, phone or otherwise, we may collect
              personal data that is necessary to authenticate you and fulfill your request. Please
              note that communications with us by email and/or phone may be monitored and recorded
              for quality assurance.
            </p>
            <p className="pl-3 underline font-medium">2.5 Supplemental Data</p>
            <p className="mb-7">
              We reserve the right to obtain additional information about you from third parties for
              purposes of verifying account data or supplementing our understanding of users. Such
              information shall be limited as to its scope, and the collection thereof shall comply
              with applicable privacy laws.
            </p>
          </div>

          {/*  */}
          <div>
            <h2 className="text-lg font-[700] mb-5">III. Our Use of Your Data</h2>
            <p className="mb-6">
              We understand the importance of protecting personal data submitted or transmitted in
              connection with our Services. We shall not communicate or disclose any personal data
              provided on the Website to any third party, except in accordance with the terms of
              this Policy, and applicable laws, regulations. <br /> All our usage of personal data
              collected in connection with our Services shall be limited to the extent required by
              our legitimate business interests and in accordance with applicable laws.
            </p>
            <p className="pl-3 underline font-medium">
              3.1 For authentication purposes and to Provide Services
            </p>
            <p className="mb-6">
              We may use your personal data to authenticate you as a user of our Services. We may
              also use your personal data in our provision of the Services, namely to process
              shipments, calculate payment and shipping charges, prevent fraud, maintain your
              account and respond to inquiries. <br />
              <br /> Additionally, we may use your personal data based on any consent or
              authorization you provided. Such authorization may be revoked at any time.
            </p>
            <p className="pl-3 underline font-medium">3.2 For Communication Purposes</p>
            <p className="mb-6">
              We may use your contact information to provide, manage and administer support
              requests. As such, we may use your contact information to respond to your questions,
              comments and requests.
            </p>
            <p className="pl-3 underline font-medium">3.3 Usage for our Business Interests</p>
            <p className="mb-6">
              We reserve the right to use your personal data to analyze and asses interest in our
              Services. We may use personal data and non-personal data to obtain analytical data
              regarding our Services, Website users and offerings. We analyze usage patterns and
              shipment data in the aggregate to better understand the efficiency of our Services.
              The analysis thereof is intended to help us improve offerings, enhance the user
              experience, streamline workflows and improve overall efficiency. <br />
              <br /> In accordance with applicable laws, we may use information collected from our
              Website in furtherance of public interest, or the public domain.
            </p>
            <p className="pl-3 underline font-medium">3.4 As Legally Required</p>
            <p className="mb-7">
              We may disclose personal data when we are required to do so by law. As such, we may
              disclose personal data pursuant to a court order, subpoena or other legal process.
              Accordingly, we may disclose your personal information pursuant to a valid court order
              or binding law, provided we give you prompt notice of our intent to disclose such
              information. This is intended to allow you to seek a protective order or other
              appropriate remedy. <br />
              <br /> We may disclose or otherwise use personal data to detect or prevent any
              fraudulent activity. We further reserve the right to disclose data to establish,
              protect or exercise any legal right available to us.
            </p>
          </div>

          {/*  */}
          <div>
            <h2 className="text-lg font-[700] mb-7">IV. Our Retention of Your Data</h2>
            <p className="pl-3 underline font-medium">4.1 Account Data</p>
            <p className="mb-6">
              We retain personal data associated with your account while your account is active and
              for a reasonable period thereafter in case you decide to reactivate the Services. We
              shall then delete your data in accordance with internal policies and applicable
              document retention regulations. <br />
              <br />
              Personal data is stored in the database servers at our data centers, or hosted by
              third parties who have entered into agreements with us that require them to comply
              with this Policy. <br />
              <br />
              We may retain your personal data to comply with legal obligations. In addition, we may
              store your personal data for payment purposes until all funds owed have been paid or
              to resolve disputes.
            </p>
            <p className="pl-3 underline font-medium">4.2 Regulatory Requirements</p>
            <p className="mb-7">
              To comply with accounting, tax, customs and other regulatory requirements, we retain
              records of shipments, payments and other transaction details for a minimum of ten (10)
              years following the date of the order. We may retain aggregated and anonymized
              transaction data on an indefinite and ongoing basis.
            </p>
          </div>

          {/*  */}
          <div>
            <h2 className="text-lg font-[700] mb-7">V. How We Share Your Data</h2>
            <p className="pl-3 underline font-medium">5.1 Third Party Providers</p>
            <p className="mb-6">
              We shall not sell, trade, rent or otherwise share your personal data with third
              parties without your prior consent. Notwithstanding the foregoing, we may share your
              personal data with third-party providers who perform services for us in connection
              with our Website and/or Services. Such third-party providers may consist of data
              processors, security consultants, auditors and shipping providers such as carriers.{" "}
              <br />
              <br /> The third party providers shall only collect and use information to the extent
              necessary to allow them to perform services in connection with our requests. As such,
              we reserve the right to share data with authorized third-party providers to allow them
              to: (i) perform duties that we subcontracted to them, including processing payments,
              fulfilling transactions, verifying customer data and providing analytical data; and/or
              (ii) protect our Website against cyberattacks or other illicit online activity. <br />
              <br /> These third party providers may access certain non-identifiable data related to
              transactions but shall not be provided identifiable personal information without your
              consent.
            </p>
            <p className="pl-3 underline font-medium">5.2 Business Events</p>
            <p className="mb-6">
              We may share and disclose data obtained from our Services and Website if we are
              involved in a: (i) merger, (ii) asset sale, (iii) acquisition, or (iv) other
              transaction regarding our ownership structure. As such, if we undergo a business
              transition such as a merger, acquisition, liquidation or sale of all or part of our
              assets, your data shall, in most instances, be part of the transferred assets
            </p>
          </div>

          {/*  */}
          <div>
            <h2 className="text-lg font-[700] mb-7">VI. Your Rights and Choices</h2>
            <p className="pl-3 underline font-medium">6.1 Opt Out of Communications</p>
            <p className="mb-6">
              You may opt-out of receiving promotional communications and emails from us by
              contacting{" "}
              <a
                className="font-[500]"
                href="mailto:support@shipitor.com"
              >
                support@shipitor.com
              </a>{" "}
              or by selecting the unsubscribe link found within each promotional email. You
              acknowledge that you cannot opt out of receiving communications and emails relating to
              account notifications and transactional messages. You may opt-out of receiving
              promotional communications from Shipitor by contacting.
            </p>
            <p className="pl-3 underline font-medium">6.2 Modification of Data</p>
            <p className="mb-6">
              You may view and modify information contained in your account by logging in and
              accessing your account settings and profile. You may therefore proceed with the
              rectification or correction of data contained therein. If you determine that your
              personal data is incomplete or requires a correction, you may request additions or
              supplements to such data by contacting our support team.
            </p>
            <p className="pl-3 underline font-medium">6.3 Deletion of Data</p>
            <p className="mb-7">
              You have the right to request the deletion of your personal data which we collected.
              To exercise this right, please contact our support team. Upon our receipt of your
              request, we shall proceed with the requested deletion, unless such data is required to
              be retained by law or for legitimate business purposes such as fraud prevention.
            </p>
          </div>

          {/*  */}
          <div>
            <h2 className="text-lg font-[700] mb-7">VII. Data Security</h2>
            <p className="mb-7">
              We established strict processes to safeguard data provided in connection with our
              Services. We take all necessary precautions to protect personal data from unauthorized
              access by third parties. In accordance with such principles, information shall only be
              disclosed to employees and affiliates on as a “need to know” basis. <br />
              <br /> While these protective methods comply with industry best practices, these
              measures do not guarantee that personal data shall not be unlawfully accessed,
              disclosed, altered or destroyed. Despite our aim to protect your personal data with
              appropriate safeguards, we cannot provide any guarantees against unauthorized access
              or loss as any information transmitted over the Internet is subject to risks. To help
              minimize damages, please notify us immediately if you become aware of a data breach or
              attempted breach.
            </p>
          </div>

          {/*  */}
          <div>
            <h2 className="text-lg font-[700] mb-7">VIII. Contact Information</h2>
            <p className="mb-7">
              As is the case with many website operators, we may use a technology commonly known as
              "cookies" in connection with our Website. Cookies consist of small data files
              downloaded onto your computer. You may disable cookies by turning them off or
              disabling them. Please note that certain portions of the Website and certain Services
              may not be provided or may not function effectively if cookies are turned off or
              removed.
            </p>
          </div>

          {/*  */}
          <div>
            <h2 className="text-lg font-[700] mb-7">IX. Data Transfers</h2>
            <p className="mb-7">
              Unless we indicate otherwise, all data collected in connection with our Services shall
              be maintained in servers located in the United States. You recognize that data may be
              transferred to servers located in another jurisdiction, which may be subject to
              different laws. We shall only permit data transfers outside of the United States if
              the data is transferred to countries that comply with strict data protection laws.
            </p>
          </div>

          {/*  */}
          <div>
            <h2 className="text-lg font-[700] mb-7">X. Third Party Links</h2>
            <p className="mb-7">
              This Policy applies solely to our Services despite that our Services may link to other
              websites and third party service providers (collectively, the “
              <strong>Third Party Links</strong>
              ”). Any Third Party Links are provided for convenience and informative purposes. Such
              Third Party Links are not governed by this Policy. We have no control over nor any
              responsibility with respect to the privacy policies and data collection of any third
              party sites.
            </p>
          </div>

          {/*  */}
          <div>
            <h2 className="text-lg font-[700] mb-7">XI. Contact Information</h2>
            <p className="mb-7">
              If you have any questions, comments or requests regarding this Privacy Policy or our
              privacy practices, please contact us by email at{" "}
              <a href="mailto:support@shipitor.com">support@shipitor.com</a> or by sending a request
              or inquiry by mail to our head office located at REECARD LLC, 145 Speckled Alder,
              Irvine, CA, 92618.
            </p>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
