import React from "react";

const Button = ({ children, className, width, ...rest }) => {
  return (
    <button
      type="button"
      className={`${className} w-${width} rounded-[5px] bg-primary text-white text-base text-center px-6 py-3 font-[500] hover:transition border-[1px] border-primary 2xl:text-xl hover:bg-white hover:text-primary`}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
