import Footer from "../components/Footer";
import Header from "../components/Header";

export default function Term() {
  return (
    <>
      <Header />
      <main className="w-full py-7 sm:py-14 max-w-[1367px] mx-auto px-4 xl:px-6">
        <div className="w-full">
          <h1 className="text-lg font-[700] mb-2">Term of Service</h1>
          <p className="text-lg font-[700] mb-8 italic">
            Effective Date of these Terms of Service: February 15, 2024 <br />
            Date of Latest Revision to these Terms of Service: February 22, 2024
          </p>

          {/*  */}
          <div>
            <h2 className="text-lg font-[700] mb-7">1. Introduction</h2>
            <p className="pl-3 underline font-medium">1.1 Overview</p>
            <p className="mb-6">
              The present Terms of Service (the “<strong>Terms</strong>”) shall govern your access
              to and usage of the shipping services and related products provided by REECARD LLC,
              operating under the name Shipitor (“<strong>Shipitor</strong>”, “<strong>we</strong>”,
              “<strong>us</strong>” and other similar terms). For reference purposes, the shipping
              solutions are described on Shipitor’s website{" "}
              <a
                className="font-semibold"
                href="www.shipitor.com"
              >
                www.shipitor.com
              </a>{" "}
              (the “<strong>Website</strong>”). <br />
              <br />
              We encourage you to read these Terms carefully before using any of the shipping
              solutions, services and/or products offered by Shipitor (collectively, the “
              <strong>Services</strong>” and each, individually, a “<strong>Service</strong>”). By
              registering for or using any of our Services, you agree to be bound by these Terms, in
              full and without restriction, and all policies and guidelines incorporated herein by
              reference. <br />
              <br /> If you do not agree to all of these Terms, your sole recourse shall be to
              refrain from using our Services. Please note that you must be at least eighteen (18)
              years old to use our Services.
            </p>
            <p className="pl-3 underline font-medium">1.2 Revisions</p>
            <p className="mb-7">
              We may modify, amend, update or change these Terms at any time at our sole discretion.
              The most recent version of these Terms shall be published on our Website. The Terms
              shall specify the date of their latest revision. <br />
              <br />
              We undertake to notify you of any significant changes to these Terms at least thirty
              (30) days in advance by email or via a notification on our Website. While we shall
              employ reasonable efforts to notify you of changes to these Terms in advance, we do
              not guarantee that you shall be informed of changes to these Terms prior to their
              publication on the Website. Consequently, you are encouraged to periodically review
              these Terms to ensure your compliance therewith. <br />
              <br /> Any modifications to these Terms shall be binding as of the date specified in
              the Terms or as of the publication of the modified Terms on our Website, whichever is
              sooner. Any usage of the Website following to the publication of the modified Terms
              shall constitute your agreement to be bound by the modified Terms.
            </p>
          </div>

          {/*  */}
          <div>
            <h2 className="text-lg font-[700] mb-7">II. Services</h2>
            <p className="pl-3 underline font-medium">2.1 Description of Services</p>
            <p className="mb-6">
              The Services we offer via our Website are comprised of various domestic shipping
              services provided through partnerships with carriers and shipping providers (the
              “Providers”), such as UPS and USPS. Unless otherwise indicated, these Terms shall not
              apply to your use of the Providers’ services. Such Providers may have their own terms
              and conditions and privacy policies. <br />
              <br />
              It is your responsibility to read and comply with the Providers’ terms before using
              the Providers’ services. We do not control nor shall we be responsible for Providers’
              provision of services, the quality of such services and the execution thereof
              <br />
              <br />
              The Services we offer further include, without limitation, shipment processing,
              shipping management software, Application Programming Interface (API) and related
              services. Such Services are intended to provide the following:
              <div className="pl-2 mb-6">
                <p>- generation of shipping labels, </p>
                <p>- printing of manifests, </p>
                <p>- tracking of shipments,</p>
                <p>- management of shipping profiles, and </p>
                <p>- access shipping-related analytics and reports. </p>
              </div>
              <br />
              <br />
              We reserve the right to extend, expand, reduce or otherwise make changes our Services
              at any time, on an as needed basis, including, without limitation, updating,
              disabling, removing, condensing or otherwise changing the Services offered.
              <br />
              <br />
              Further, we may discontinue, limit, modify any Service, or impose additional
              requirements to the provision of any Service, as may be reasonably required to comply
              with any applicable laws and/or policies. If changes in applicable laws and/or
              policies materially affect the Services or would impose further compliance
              requirements, we shall provide you with notice to the extent that said changes would
              impact your obligations.
            </p>
            <p className="pl-3 underline font-medium">2.2 Availability of Services</p>
            <p className="mb-6">
              We intend for our Services to be available on a continuous uninterrupted basis,
              however, we are unable to guarantee continuous or uninterrupted access or use of our
              Services. We may suspend access for maintenance or scheduled downtime. Where possible,
              we shall notify you in advance of any disruption. <br />
              <br />
              We further the right to perform repairs and maintenance or to upgrade or enhance our
              network, Website and Services, with or without prior notice or liability to you, even
              if such maintenance causes a partial or full disruption of the Services. We shall use
              commercially reasonable efforts to perform the maintenance as to avoid unduly
              interfering with the provision of the Services.
            </p>
            <p className="pl-3 underline font-medium">2.3 Security</p>
            <p className="mb-6">
              To protect and secure your data, we use security controls that comply with ISO
              27001/27002 standards for information security management. We also work with SOC 2
              Type II audited data centers.
              <br />
              <br />
              Despite our best efforts, you acknowledge that no security controls are perfect or
              impenetrable. We disclaim any guarantees provided against unauthorized access or loss
              of data. You shall be solely responsible for managing access to your credentials to
              prevent unwanted exposure of your account and data.
            </p>
          </div>

          {/*  */}
          <div>
            <h2 className="text-lg font-[700] mb-7">III. Restricted Activities</h2>
            <p className="pl-3 underline font-medium">3.1 Prohibited Items</p>
            <p className="mb-2">
              You agree to comply with all applicable laws and regulations when using our Services.
              You are strictly prohibited from using our Services to ship any prohibited or
              restricted items. For reference purposes, prohibited and restricted items shall refer
              to those prohibited and restricted by UPS. We refer you to UPS’s terms in this regard,
              which shall be deemed to be incorporated into these Terms by reference:
              <a
                className="font-medium"
                href="https://www.ups.com/us/en/support/shipping-support/shipping-special-care-regulated-items/prohibited-items.page"
              >
                https://www.ups.com/us/en/support/shipping-support/shipping-special-care-regulated-items/prohibited-items.page
              </a>
              and USPS (
              <a
                className="font-medium"
                href="https://www.usps.com/ship/shipping-restrictions.htm"
              >
                https://www.usps.com/ship/shipping-restrictions.htm
              </a>
              ).
              <br />
              <br />
              For information and reference purposes, it is strictly prohibited to ship any of the
              following items, which shall be deemed restricted as per these Terms:
            </p>
            <div className="pl-3 mb-6">
              <p>(a) Hazardous materials (HAZMAT);</p>
              <p>(b) Defective items (including batteries);</p>
              <p>(c) Illegal substances, cannabis, drugs, medication;</p>
              <p>(d) Items exceeding carrier size/weight restrictions; and</p>
              <p>(e) International shipments requiring customs clearance</p>
            </div>
            <p className="mb-6">
              Please note that restricted and prohibited items lists may vary from carrier to
              carrier. You are solely responsible for ensuring you comply with all applicable
              regulations and restrictions, including those of the selected carrier.
              <br />
              <br />
              You acknowledge that perishable items and food may be shipped, at your sole risk, and
              no guarantees are provided with respect to the freshness and quality thereof.
            </p>
            <p className="pl-3 underline font-medium">
              3.2 No Resale or Unauthorized Use of Services
            </p>
            <p className="mb-6">
              You may use the Services for your internal use only: You may not resell, transfer,
              license, lease, assign, or otherwise provide the benefits of our Services to any third
              party without our express written consent.
              <br />
              <br />
              You may not utilize our shipping labels, manifest data or other resources for any
              purpose except to ship packages using our Services. Any other usage of our Services is
              strictly prohibited and may give rise to sanctions imposed upon by us and applicable
              laws.
            </p>
            <p className="pl-3 underline font-medium">3.3 No Competitive Use</p>
            <p className="mb-6">
              Considering the proprietary nature of our Services, you are strictly prohibited from
              utilizing any data, analytics or other insights gained from our Services to develop or
              enhance a competing shipping services platform. As such, you are prohibited from using
              our pricing, carrier rates or shipment volume data to model, develop or market a
              competing software/SaaS shipping solution.
              <br />
              <br />
              You confirm and agree that all data regarding your shipments, transactions, rates and
              other details constitutes our proprietary information. You may only access and use
              such data for your own internal shipping operations, and not for any other purposes.
              You may not redistribute raw data outside your organization.
              <br />
              <br />
              Any breach of the non-competitive use obligations shall be deemed a breach of
              contract, subject to the penalties and sanctions set forth in these Terms.
            </p>
            <p className="pl-3 underline font-medium">3.4 Compliance Monitoring</p>
            <p className="mb-6">
              We reserve the right to monitor shipments, accounts and related data to ensure their
              compliance with these Terms and applicable laws. As part of our monitoring process, we
              may request evidence of compliance for high-risk shipments. You shall be required to
              provide the evidence sought upon our request. Failure to provide such evidence may
              result in the cancellation of your shipment, without liability, notice or delay.
            </p>
            <p className="pl-3 underline font-medium">3.5 Additional Restrictions</p>
            <p className="mb-4">
              You agree to use the Services only in a manner that fully complies with all applicable
              laws, as well as these Terms. You therefore represent, warrant, and agree that you
              shall not use the Services to:
            </p>
            <p className="mb-6">
              (a) Breach any applicable law, government order, regulation or policy, including but
              not limited to export laws;
            </p>
            <p className="mb-6">
              (b) Infringe or violate any third party intellectual property rights (including those
              of Shipitor or any courier);
            </p>
            <p className="mb-6">
              (c) Breach or violate of any Courier terms, rules, or policies, or agreements with any
              Courier;
            </p>
            <p className="mb-6">
              (d) Impair, interfere with or damage the Services, our servers or any portion thereof
              in any way;
            </p>
            <p className="mb-6">(e) Send harmful components, viruses or damaging files;</p>
            <p className="mb-6">
              (f) “Crawl,” “scrape,” any page, data, or portion of or relating to the Services or
              Content (through use of manual or automated means);
            </p>
            <p className="mb-6">
              (g) Engage in unlawful or illegal, fraudulent, malicious or otherwise discriminatory
              conduct, or encourage others to engage in such illicit purposes;
            </p>
            <p className="mb-6">
              (h) Access the Services using any unauthorized means or technology, or bypass
              established security measures;
            </p>
            <p className="mb-6">
              (i) Decompile, reverse engineer, or otherwise attempt to obtain the source code or
              underlying ideas or information of or relating to the Services.
            </p>
            <p className="mb-6">(j) Use an account belonging to another user on the Website;</p>
            <p className="mb-6">
              (k) Jeopardize the security of accounts, emails or API Keys (such as allowing someone
              else to log in);
            </p>
            <p className="mb-6">
              (l) Attempt, in any manner, to obtain the password, account, or other security
              information from any other user;
            </p>
            <p className="mb-6">
              (m) Violate the security of any computer network, or cracks any passwords or security
              encryption codes;
            </p>
            <p className="mb-6">
              (n) Run Maillist, Listserv, any form of auto-responder or “spam” on the Services, or
              any processes that run or are activated while you are not logged into the Services, or
              that otherwise interfere with the proper working of the Services (including by placing
              an unreasonable load on the Services’ infrastructure)
            </p>
            <p className="mb-6">
              (o) Act in a manner that conflicts with the spirit of these Terms
            </p>
            <p className="pl-3 underline font-medium">3.6 Violations</p>
            <p className="mb-7">
              Any violation of the above restrictions may result in the suspension, termination
              and/or cancellation of your account. We may provide evidence of illegal shipments to
              appropriate authorities.
            </p>
            <p className="pl-3 underline font-medium">3.7 Software Provision</p>
            <p className="mb-7">
              If software is provided by us in connection with the Services, we shall grant you a
              personal, limited, revocable, non-exclusive, non-assignable and non-transferable
              license to use such software, solely for the purpose of using the Services. Any
              attempt to sublicense, assign or transfer such license shall be void and shall result
              in the termination of the license.
              <br />
              <br />
              This license shall commence upon your receipt of the Services, and shall terminate
              upon the termination of our provision of the Services to you. Upon request, you shall
              return or destroy the software and any related written material, together with any
              copies thereof. <br />
              <br />
              We reserve the right to upgrade our software or discontinue support for earlier
              versions of our software at any time.
            </p>
          </div>

          {/*  */}
          <div>
            <h2 className="text-lg font-[700] mb-7">IV. Payment</h2>
            <p className="pl-3 underline font-medium">4.1 Billing and Fees</p>
            <p className="mb-6">
              You agree to pay us the recurring service charges and other fees, if applicable, for
              your use of the Services.
              <br />
              <br />
              You agree to pay all applicable shipping fees and charges outlined on our Website,
              including account fees, shipping rates, label fees, pick up/drop off fees, fuel
              surcharges, additional services fees and applicable taxes. You acknowledge that
              additional fees may apply for certain features or services.
              <br />
              <br />
              Please note that rates depend on shipment details, carrier, service level and other
              variables. Rates are subject to change without prior notice. You are responsible for
              monitoring rate changes and complying with applicable pricing.
              <br />
              <br />
              You shall be responsible for the payment of added billing fees, cash advances, credit
              card fees, interest charges, and other similar charges.
              <br />
              <br />
              Billing shall commence on the date you subscribe to our Services. You shall be
              responsible for providing us with a valid email address, billing address, and a valid
              payment method prior to your subscription to our Services. As part of a subscription,
              you shall be provided with a predetermined number of shipping labels within a
              specified period. <br />
              <br /> If you do not have a subscription, we shall invoice you on a weekly basis for
              all fees accrued during the previous week. Payment shall be due and payable within
              seven (7) days of the date of the invoice.
            </p>
            <p className="pl-3 underline font-medium">4.2 Payment Methods</p>
            <p className="mb-6">
              You agree that you are the authorized user and/or owner of any payment information and
              method you provide to us. You agree that we shall charge your credit card or the
              payment information provided. As such, you hereby authorize us to: (a) automatically
              bill the credit card provided to satisfy our invoices; or (b) automatically debit
              (charge) your checking account electronically via automated clearing house (ACH). All
              payments made in USD shall be paid by ACH bank transfer. We may store, or use a third
              party to store your payment information to provide for recurring payments.
              <br />
              <br />
              We collect payment data to process transactions and prevent fraud. By providing this
              data, you consent to data handling as detailed in our Privacy Policy.
              <br />
              <br />
              Any payments to Canadian bank accounts shall be converted from USD to CAD according to
              the exchange rate in effect at the time of the conversion. Any payments in Canadian
              dollars shall be made via a local bank account or Interac.
            </p>
            <p className="pl-3 underline font-medium">
              4.3 Promotions and Trials and Referral System
            </p>
            <p className="mb-6">
              We may, at our discretion, offer promotions or trials for discounted shipping labels.
              If you choose to participate in such promotions or trials, you agree to be bound by
              any terms we set out as applicable such promotions and trials.
            </p>
            <p className="mb-6">
              Further, we offer a referral system, whereby you may be eligible for discounted fees
              if you successfully refer our Services to a third party. In the event that you refer
              our Services to a third party who subsequently signs up for our Services, as
              demonstrated using an online referral code, you shall be entitled to a discount on our
              Services for subsequent purchases. The discount shall be available thirty (30) days
              following the successful referral, or may be paid to you by bank transfer within the
              same delay.
            </p>
            <p className="pl-3 underline font-medium">4.4 Suspension of Services</p>
            <p className="mb-7">
              If your payment information is invalid or otherwise unacceptable, we reserve the right
              to cancel the provision of the subscription.
              <br />
              <br />
              Past due amounts bear interest at the rate of 1.5% per month or the maximum rate
              allowed by law, whichever is less. Late payments and returned checks are subject to
              late payment fees and returned check fees, respectively.
              <br />
              <br />
              Moreover, if you (a) fail to make any payment when due and such failure continues for
              five (5) days after written notice from us, or (b) fail to observe or perform any
              other material term of these Terms and such failure continues for thirty (30) days
              after written notice from us, we may elect to: (i) terminate your subscription, in
              whole or in part, at our discretion; and/or (ii) pursue all remedies we may have at
              law or in equity. <br />
              <br /> You agree to reimburse us for all reasonable attorney fees and other costs
              incurred by us relating to collecting delinquent payments or non-payment.
            </p>
            <p className="pl-3 underline font-medium">4.5 Disputes relating to Invoices</p>
            <p className="mb-7">
              To dispute an invoice or amount invoiced, you shall pay the undisputed amount and
              submit written notice of the disputed amount. The notice of the disputed amount shall
              include details of the nature of the dispute and the Services and invoice(s) disputed.{" "}
              <br />
              <br /> You must submit the notice of dispute within ten (10) days of the date of the
              invoice. If the dispute notice is not sent within such delay, you shall be deemed to
              have waived all rights to dispute the applicable charges, unless otherwise provided by
              law. <br />
              <br /> Upon our receipt of the written notice, we shall perform the necessary
              verifications and take appropriate action to remedy the situation, including by
              issuing a refund or a credit. <br />
              <br /> Our intention is to provide transparent billing for the subscriptions
              purchased. Despite this, billing errors may occur. In the event of a billing error, we
              shall provide the appropriate refund or credit against future payments owed. The
              billing error shall promptly be corrected.
            </p>
            <p className="pl-3 underline font-medium">4.6 Taxes and Fees</p>
            <p className="mb-7">
              Unless otherwise indicated, the payments for subscriptions are exclusive of any taxes
              and duties. You shall be responsible for paying taxes charged in connection with the
              purchase of the subscription. International charges and taxes are subject to change at
              any time, without notice. <br /> If any amounts paid for the Services are refunded by
              us, applicable taxes may not be refundable.
            </p>
            <p className="pl-3 underline font-medium">4.7 Account Credit and Refunds</p>
            <p className="mb-7">
              We may, without obligation and at our sole discretion, offer you credits, which may be
              applied to future Services. Account credits are non-transferable and non-refundable.
              Credits shall be automatically applied toward eligible Services during the checkout
              option. Credits are linked to your account, and shall automatically expire upon the
              termination of your account. <br />
              <br /> Refunds shall not be provided for Services that have been provided. We do not
              have the capability to void or terminate labels. We shall not issue refunds for labels
              not used within five (5) days of the creation and ship date. <br />
              <br /> Notwithstanding the foregoing, we may issue refunds at our discretion. We shall
              not be under any obligation to issue a refund under any circumstances. In all cases,
              we shall, at our discretion, determine the amount of the refund. Any past refund shall
              not entitle you to subsequent or future refunds.
            </p>
            <p className="pl-3 underline font-medium">4.8 Payment Processing</p>
            <p className="mb-7">
              We use Stripe for purposes of payment processing. Please note that all payments made
              for our Services are handled through Stripe, unless otherwise specified. You therefore
              understand that you may be bound and subject to certain Stripe policies and terms.
            </p>
          </div>

          {/*  */}
          <div>
            <h2 className="text-lg font-[700] mb-7">V. Account Management</h2>
            <p className="pl-3 underline font-medium">5.1 Registration</p>
            <p className="mb-6">
              You may visit or access the Website without creating an account thereon, however, to
              benefit from our Services, you shall be required to register and create an account.
              You understand that the Services shall be inaccessible without a valid account.
            </p>
            <p className="pl-3 underline font-medium">
              5.2 Personal Data Provided as part of the User Account
            </p>
            <p className="mb-6">
              In furtherance of the creation of your account, you may be asked to provide personal
              or private information, such as your email address. <br />
              <br /> You confirm that all personal data you provided in connection with your account
              is accurate, true, complete and current to the best of your knowledge. You shall be
              responsible for updating any data submitted as part of your account which becomes
              outdated, expired or no longer valid. <br />
              All information or data submitted as part of an account shall be handled according to
              our Privacy Policy, as published on our Website.
            </p>
            <p className="pl-3 underline font-medium">5.3 Account Security</p>
            <p className="mb-7">
              Your account on our Website shall be secured by a password. You shall be responsible
              for ensuring the password to your account remains confidential. In the event of a
              security breach, such as loss or compromised password, you agree to notify us at your
              earliest convenience. <br />
              <br /> Your account may only be used by the authorized user named in such account. It
              is prohibited to use an account that does not belong to you or for which you do not
              have the right to use. You further agree to refrain from sharing login details with
              any third party. As such, it is prohibited to transfer your account to any third
              party, including employees, colleagues or other third parties. Any usage or access of
              another user’s account on the Platform is strictly prohibited. <br />
              <br />
              You are responsible for all acts, content, and omissions arising out of or relating to
              your account. We decline full liability for losses or damages caused by unauthorized
              usage of your account. You shall be held liable for any purchases and charges incurred
              by or using your account.
            </p>
            <p className="pl-3 underline font-medium">5.4 Subscription Services</p>
            <p className="mb-7">
              Your subscription to the Services shall begin on the date at which you activate your
              subscription. Your subscription shall remain in effect until the earlier of (a) you
              fail to pay the fees on their due date, (b) you cancel your subscription to the
              Services, or (c) the cancelation or termination of the Service by us.
            </p>
            <p className="pl-3 underline font-medium">5.5 Notice of Cancellation</p>
            <p className="mb-7">
              Unless otherwise specified, the Services shall automatically renew for subsequent
              periods. we shall be authorized to automatically charge your credit card or payment
              information on or prior to the renewal date. <br />
              <br /> Any cancellation notice transmitted by you to us shall be provided at least ten
              (10) days notice prior to the next scheduled billing date to avoid being billed for
              the next billing cycle. If you provide the notice of cancellation less than ten (10)
              days prior to the next scheduled billing date, you shall be billed for the next
              billing cycle in full.
            </p>
            <p className="pl-3 underline font-medium">5.6 Cancellation Process</p>
            <p className="mb-7">
              You may cancel your subscription to our Services by contacting us by telephone or
              email (<a href="mailto:support@shipitor.com">support@shipitor.com</a>). We shall not
              accept cancellation notices sent by fax, SMS or other electronic methods. Your failure
              to cancel your subscription in accordance with this section shall result in ongoing
              service charges, fees and taxes. <br />
              <br />
              Upon our receipt of your termination notice, we shall provide you with an email
              confirmation of the Services canceled. In the event that you subscribe to ancillary
              services provided by us, the cancellation of the Services for any reason shall also
              result in the cancellation of such ancillary services. <br />
              <br /> Please be advised that unless otherwise indicated, the entirety of your data,
              including all shipping history and preferences, shall be deleted on or after the date
              of termination or cancellation. Such data may however remain available, subject to our
              data retention policies. <br />
              <br />
              We shall not refund, in whole or in part, or issue credits for any charges already
              billed to your account. In the event you signed up for a minimum commitment period,
              you shall remain responsible for all fees for the entire minimum commitment period,
              even if canceled or terminated sooner.
            </p>
          </div>

          {/*  */}
          <div>
            <h2 className="text-lg font-[700] mb-7">VI. Intellectual Property</h2>
            <p className="pl-3 underline font-medium">6.1 Shipitor’s Intellectual Property</p>
            <p className="mb-6">
              All corporate names, service marks, trademarks, trade names, brandmarks, branding,
              software, codes, logos and domain names of Shipitor are and shall remain the exclusive
              property of Shipitor. These may be protected by trademark, copyright, patent and other
              intellectual property laws and international treaty provisions. <br />
              <br />
              Our trademarks and logos may not be reproduced or shared without our explicit prior
              written consent. All intellectual property directly relating to Shipitor and our
              Services remains our sole property and we are the exclusive owner of such intellectual
              property throughout the world, without limitations of time or space. Nothing in these
              Terms shall grant you the right or license to use any of the foregoing. You may not
              copy, distribute, modify or create derivative works based upon, incorporating or
              otherwise referencing our intellectual property.
            </p>
            <p className="pl-3 underline font-medium">6.2 Licensed Intellectual Property</p>
            <p className="mb-6">
              Any content that has been licensed to us remains the property of its respective
              owners. The reproduction, transmission, sharing and distribution of all or part of any
              the content on our Website is expressly prohibited, except as provided under these
              Terms.
            </p>
            <p className="pl-3 underline font-medium">6.3 Suggestions and Feedback</p>
            <p className="mb-7">
              In the event that you provide us with suggestions, enhancement requests,
              recommendations, proposals, documents, or other feedback with respect to the Services
              or Website (the “<strong>Feedback</strong>”), you hereby grant us full ownership of
              the Feedback, without compensation to you or attribution of any kind. As such, we
              shall benefit from the worldwide, irrevocable, perpetual right to use, modify, and
              distribute your Feedback as we see fit.
            </p>
            <p className="pl-3 underline font-medium">6.4 Third party intellectual property</p>
            <p className="mb-7">
              Our Website may include links to third party websites, sites, pages and/or
              applications. To properly identify the third party, images of the third party’s
              trademarks and intellectual property may be published on the Website. We may publish
              the logos of the third party, while not having any affiliation to such third party, to
              allow you to identify the services associated with such mark. In accordance with
              applicable trademark laws, the publication of the logos of third parties constitutes
              nominative fair use.
            </p>
          </div>

          {/*  */}
          <div>
            <h2 className="text-lg font-[700] mb-7">VII. Disclaimers; Limitations on Liability</h2>
            <p className="pl-3 underline font-medium">7.1 AS IS Service; No Warranties</p>
            <p className="mb-6">
              We shall provide the Services, and the Website on an “as is” and “as available” basis
              without warranty or representation of any kind, save and except for any warranties
              provided by applicable laws which cannot be discarded in a legal document.
              <br />
              <br />
              Our Services and Website shall be provided without warranties of any kind, including
              warranties that are express, statutory, implied warranties of title, non-infringement,
              merchantability and fitness for a particular purpose, and any warranties implied by
              any course of performance or usage of trade, all of which are expressly disclaimed. We
              cannot guarantee our provision of the Services shall fulfill your needs or
              expectations.
              <br />
              <br />
              We cannot guarantee that our Services and Website shall be continuously available,
              error-free and uninterrupted. While we agree to use reasonable efforts to correct
              issued or defects, we cannot guarantee that issues or defects shall be corrected in a
              timely manner or even corrected at all. Considering the risks associated with online
              activity, we cannot guarantee that our Website and Services shall be free of harmful
              components, such as viruses. In consideration thereof, you agree to use our Services
              and Website at your own risk.
            </p>
            <p className="pl-3 underline font-medium">7.2 Service Levels</p>
            <p className="mb-6">
              While we strive to provide for high uptime and reliability, we are unable to guarantee
              that any Service levels or access to our Services shall be uninterrupted or error free
              at all times. You understand that performance, Service levels and access to our
              Services shall vary based on your location, device, Internet connection speed and
              other variables outside of our control.
            </p>
            <p className="pl-3 underline font-medium">7.3 Loss or Damage to Shipments</p>
            <p className="mb-6">
              We are and shall remain committed to delivering shipping Services using the utmost
              care, ensuring timely and secure delivery of packages. In the event of any Service
              issues, we shall employ commercially reasonable efforts to address and resolve them
              promptly. <br />
              <br /> Please be advised that we do not perform the shipping, nor do we take custody
              or control of shipments. As such, we shall not be liable for any damage, loss or theft
              of packages following the delivery thereof by the appropriate carrier. <br />
              <br />
              In case of any issues with any one of our carriers, you agree to follow the procedure
              set forth in these Terms. As such, you agree not to file any claims against carriers
              and instead, to contact us immediately to report any issues or concerns with a Service
              and/or a carrier. Any failure to notify us promptly and prior to filing claims against
              carriers shall render us unable to process your claim and provide compensation. By
              failing to comply with the present procedures, you shall be deemed to waive any rights
              you may have pursuant to these Terms in connection with a claim for loss or damages.
            </p>

            <p className="mb-2">
              In case of any loss or damaged package, you must notify us promptly via email at{" "}
              <a
                className="font-medium"
                href="mailto:support@shipitor.com"
              >
                support@shipitor.com
              </a>{" "}
              or certified mail. Your notice shall contain the following information:
            </p>
            <div className="mb-6 ml-3">
              <p>(i) your name and contact information</p>
              <p>(ii) details of the issue reported</p>
              <p>(iii) shipment details (tracking number, shipment date, etc.)</p>
              <p>(iv) a detailed description of the damage or loss reported</p>
              <p>(v) photographs of the items and packaging (if applicable)</p>
              <p>(vi) any supporting documentation, such as invoices or receipts.</p>
            </div>
            <p className="mb-6">
              We reserve the right to request additional information to assess your claim. You agree
              to provide us with all requested information in a timely manner, as to avoid delays in
              processing your claim. We undertake to collaborate with you and the carrier to
              investigate and attempt to resolve the matter in a mutually acceptable manner.
            </p>
            <p className="mb-6">
              To the fullest extent permitted by law, our maximum liability for any loss or damage
              sustained while a shipment is in transit shall be capped at one hundred dollars
              ($100.00) per package. Such maximum liability shall be applicable to packages of
              perishables and food items. The payment of such sum by us shall not be construed as an
              admission nor as a recognition of our liability. Rather, such sum shall constitute a
              courtesy offered for in-transit issues. You agree that the sum provided does not
              constitute nor should be construed as insurance and/or a guarantee of any kind.
            </p>
            <p className="mb-6">
              If the assessed damage or loss falls within our maximum liability of one hundred
              dollars ($100.00) per package, as determined at our sole discretion, we shall pay the
              value of the loss or one hundred dollars ($100.00), whichever is lower, by ACH Bank
              Transfer to your bank account. <br />
              Payments remain due and payable for damaged or delayed packages, unless otherwise
              specified.
            </p>
            <p className="pl-3 underline font-medium">7.4 Limitation on Liability</p>
            <p className="mb-7">
              You recognize that technical issues may arise when using our Services and/or Website.
              Such issues may delay, suspend, terminate or completely prevent our provision of the
              Services and/or access to our Website. We shall not be liable for any loss, damage or
              other adverse effects caused by interruptions, suspensions or the ability to access
              our Services and/or Website. <br />
              <br /> Acknowledging our role and the extent of the Services, you agree that we shall
              not be liable for any erroneous, faulty, incomplete, incorrect or otherwise damaging
              services provided by third parties. We are unable to guarantee that the third parties
              shall provide the shipping services in accordance with your instructions, expectations
              and timeline. Further, we cannot control the quality or the effectiveness of the
              shipping services and other services provided by third parties. Any warranties,
              representations and terms provided by the third parties shall not be binding upon us.
              <br />
              <br />
              Subject to these Terms and applicable laws, we and our affiliates, agents, officers,
              directors, contractors, and employees shall not be liable for any indirect,
              incidental, special, punitive or consequential damages, replacement costs, or losses
              of any kind, including but not limited to loss of revenue, loss of profits, loss of
              opportunity, loss of content, loss of data, loss of packages, and other losses,
              without limitation, even if we have been advised of the possibility of such damages.{" "}
              <br /> To the fullest extent permitted by applicable law, our aggregate liability
              shall not exceed the sum you paid to us for our Services in the six (6) months
              preceding the event giving rise to the liability.
            </p>
          </div>

          {/*  */}
          <div>
            <h2 className="text-lg font-[700] mb-7">VIII. Indemnification</h2>
            <p className="mb-7">
              You agree to indemnify and hold us, our affiliates, agents, officers, directors,
              contractors, and employees harmless from and against all losses, damages, liabilities,
              costs and expenses in connection with any claims, actions, litigation and other
              proceedings instituted by a third party, arising out of (i) any negligent action,
              misconduct, fault, omission or gross negligence you committed; (ii) any intentionally
              wrongful act(s) you committed, (iii) your violation, contravention or breach of any
              covenant or obligation under these Terms; (iv) any false representation or warranty
              you made in connection with these Terms; or and/or (v) your execution or
              non-performance of your obligations under these Terms. In accordance with applicable
              laws, your indemnification obligations towards us shall not apply in case of fraud or
              gross negligence committed by us.
            </p>
          </div>

          {/*  */}
          <div>
            <h2 className="text-lg font-[700] mb-7">9. Dispute Resolution</h2>
            <p className="mb-7">
              If you have any dispute or issues regarding these Terms and/our Services, please
              contact us by email at <a href="mailto:support@shipitor.com">support@shipitor.com</a>.
              We shall employ good faith efforts to resolve such dispute or issues through informal
              processes.
            </p>
            <p className="pl-3 underline font-medium">9.1 Mediation</p>
            <p className="mb-6">
              If the dispute cannot be resolved informally within thirty (30) days, you agree to
              proceed by non-binding mediation before initiating legal proceedings. The unresolved
              dispute shall then be handled through mediation administered in accordance with the
              standards set forth by the International Chamber of Commerce. All mediation sessions
              shall be held in Irvine (California) before a mutually agreed upon mediator. The
              language to be used in the mediation sessions shall be English. Each party to the
              mediation shall bear its own costs.
            </p>
            <p className="pl-3 underline font-medium">9.2 Court Proceedings</p>
            <p className="mb-6">
              Any dispute remaining unresolved following mediation may be brought before the
              competent courts located in the State of California.
            </p>
            <p className="pl-3 underline font-medium">9.3 Remedies and Injunctions</p>
            <p className="mb-6">
              You recognize that any violation of these Terms or any breach of any section thereof
              may cause us significant harm and damages, especially if such violation or breach
              relates to data security, intellectual property or unauthorized usage of the Website.
              Further, you acknowledge that monetary damages may not be a sufficient remedy for a
              violation of these Terms. We shall be entitled, without waiving any other rights or
              remedies, to such injunctive or equitable relief as may be deemed proper by a court of
              competent jurisdiction.
            </p>
            <p className="pl-3 underline font-medium">9.4 Waivers to Jury Trial and Class Action</p>
            <p className="mb-6">
              You waive your right to a jury trial in any litigation proceedings commenced in
              connection with these Terms. You acknowledge that all claims shall be brought in your
              individual capacity, and not as part of a class action or another party’s claim.{" "}
              <br />
              <br /> If any proceeding is commenced in connection with these Terms, the prevailing
              party, as determined by the court, shall be entitled to claim all reasonable attorney
              fees, legal costs and necessary disbursements incurred in such action or proceeding.
            </p>
            <p className="pl-3 underline font-medium">9.5 Time Frame</p>
            <p className="mb-6">
              Any dispute relating to these Terms shall be introduced within one (1) year of the
              event giving rise to the dispute. Any dispute introduced after the one (1) year delay
              shall be barred.
            </p>
          </div>

          {/*  */}
          <div>
            <h2 className="text-lg font-[700] mb-7">X. Account Cancellation; Termination</h2>
            <p className="pl-3 underline font-medium">10.1 Cancellation by You</p>
            <p className="mb-6">
              You shall benefit from the right to request cancellation of your Shipitor account at
              any time. You may request such cancellation via email or by phone. To avoid being
              charged for the next billing cycle, you must provide us with at least ten (10) days
              notice prior to the next billing date.
            </p>
            <p className="pl-3 underline font-medium">10.2 Cancellation by Shipitor</p>
            <p className="mb-6">
              We reserve the right to suspend or terminate your access and account for any reason we
              deem to be appropriate, including but not limited to a breach of these Terms, illegal
              or fraudulent shipments, excessive disputes/chargebacks or non-payment. We shall
              attempt to notify you prior to the suspension or termination of your Shipitor account.
              Notwithstanding the foregoing, certain violations may result in the immediate loss of
              account access to prevent loss or damages, or to comply with legal obligations.
            </p>
            <p className="pl-3 underline font-medium">
              10.3 Effect of Termination and Ongoing Obligations
            </p>
            <p className="mb-7">
              Upon the termination of your Shipitor account for any reason, your access and ability
              to use our Services shall immediately terminate. Further, you may no longer be able to
              access data contained in your Shipitor account, including shipment history, tracking
              data, saved shipping profiles and other account data. The termination of your Shipitor
              account shall not relieve you of your obligation to pay fees accrued and incurred
              under your account.
            </p>
          </div>

          {/*  */}
          <div>
            <h2 className="text-lg font-[700] mb-7">XI. General Provisions</h2>
            <p className="pl-3 underline font-medium">11.1 Entire Agreement</p>
            <p className="mb-6">
              These Terms, together with other documents to be delivered hereunder, constitute the
              entire agreement between you and us pertaining to the subject matter of these Terms
              and supersede all prior agreements, understandings, negotiations and discussions,
              whether oral or written, between you and us. There are no warranties, representations
              or other agreements in connection with the subject matter of these Terms except as
              specifically set forth herein.
            </p>
            <p className="pl-3 underline font-medium">11.2 Waivers</p>
            <p className="mb-6">
              No waiver of any of the provisions of these Terms shall be deemed or shall constitute
              a waiver of any other provision (whether or not similar) nor shall such waiver
              constitute a continuing waiver unless otherwise expressly provided. <br />
              <br /> If we do not require the performance of an obligation specified in these Terms,
              this shall not serve as a waiver of our rights. Further, if we suspend, delay or fail
              to exercise any rights granted to it under these Terms, this shall not operate as a
              waiver of our rights.
            </p>
            <p className="pl-3 underline font-medium">11.3 Severability</p>
            <p className="mb-6">
              If any term or section in these Terms is held to be unenforceable or unlawful, the
              term or section shall be severed. The severed term shall not affect the validity and
              enforceability of the remainder of the Terms.
            </p>
            <p className="pl-3 underline font-medium">11.4 Force Majeure</p>
            <p className="mb-6">
              Force Majeure events consist of unforeseeable and irresistible events, such as
              government orders, epidemics, pandemics, labor strikes, wars, acts of God, tornados,
              hurricanes and other natural disasters. We shall not be responsible for any failure to
              perform our obligations due to a Force Majeure event. <br />
              <br /> During a Force Majeure event, our obligations under these Terms shall be
              suspended, until the Force Majeure event has ceased, and been resolved, as confirmed
              by us. If the Force Majeure event persists beyond ten (10) consecutive days, we may
              elect to cease performing our obligations on a permanent basis, without compensation
              or liability.
            </p>
            <p className="pl-3 underline font-medium">11.5 Communications</p>
            <p className="mb-6">
              You understand and agree that we may send emails regarding these Terms, the Website,
              the Services and any matters contemplated under these Terms. You are required to
              provide your current and valid email address. Any emails sent by us to the email
              address you provided shall be deemed properly transmitted and effective. Please note
              you may opt out of receiving our promotional emails and newsletter by following the
              instructions provided.
            </p>
            <p className="pl-3 underline font-medium">11.6 Communications</p>
            <p className="mb-6">
              By using Shipitor services, you agree to receive essential transactional and
              informational communications from us relating to your orders, payments, account status
              and similar operational topics. You may opt out of promotional communications at any
              time. Read our Privacy Policy to understand what data we collect and how we use it.
            </p>
            <p className="pl-3 underline font-medium">11.7 Assignment</p>
            <p className="mb-6">
              You may not assign all or part of your rights and obligations hereunder to any person,
              without our prior written consent, which shall not be unreasonably withheld or
              delayed. <br />
              <br /> We may assign all or part of our rights and obligations hereunder to any
              person, entity, business or company. We may, without notice, assign our rights and
              obligations hereunder to any parent, affiliate or subsidiary of Shipitor or pursuant
              to any merger, acquisition, or corporate reorganization, provided that our assignee
              assumes all of our obligations and liabilities hereunder.
            </p>
            <p className="pl-3 underline font-medium">11.8 Governing Law</p>
            <p className="mb-7">
              These Terms are governed by the laws of the State of California (excluding any
              conflict of laws rule or principle, which might refer to the laws of another
              jurisdiction).
            </p>
          </div>

          {/*  */}
          <div>
            <h2 className="text-lg font-[700] mb-7">XII. Export Controls</h2>
            <p className="mb-7">
              You agree to fully comply with all relevant export laws and regulations of the United
              States, including the <i>U.S. Export Administration Regulations</i>, administered by
              the Department of Commerce. You also expressly agree to not export, directly or
              indirectly, re-export, divert, or transfer any goods, technology and related technical
              data to any destination, company, or person restricted or prohibited by U.S. export
              controls.
            </p>
          </div>

          {/*  */}
          <div>
            <h2 className="text-lg font-[700] mb-7">XIII. Contact Information</h2>
            <p className="mb-7">
              You are invited to contact us by email at{" "}
              <a href="mailto:support@shipitor.com">support@shipitor.com</a>
              with questions, inquiries, feedback and comments regarding these Terms, your use of
              the Services and/or our Website. You may also submit questions, inquiries, feedback
              and comments to us in writing and delivered by U.S. mail with postage prepaid, or
              certified mail, return receipt requested to our head office:
              <br />
              REECARD LLC <br />
              145 Speckled Alder <br />
              Irvine, CA <br /> 92618
            </p>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
