import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Loader from "../components/Loader";
import Auth from "../layout/Auth";
import Button from "../components/Button";
import { REACT_APP_KEY_NAME } from "../utils/constants";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Payment = () => {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const email = params.get("email");
  const [clientSecret, setClientSecret] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const getPaymentIntent = async () => {
    try {
      const options = {
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/billing/create-payment-intent`,
        headers: { "Content-Type": "application/json" },
        data: { email },
      };

      const res = await axios.request(options);
      if (res?.status === 200) {
        setClientSecret(res.data?.client_secret);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!email) {
      history.push("/signup");
      return;
    }
    getPaymentIntent();
    return () => {
      setClientSecret();
    };
  }, []);

  return (
    <Auth
      subTitle="Last step! We never share your credit card information.
All payments are secured by Stripe. Your card will be charged $1.99."
      submitHandle={e => e.preventDefault()}
    >
      {!isLoading && clientSecret && (
        <div className="mb-3">
          <Elements
            stripe={stripePromise}
            options={{
              clientSecret,
              appearance: { theme: "stripe" },
            }}
          >
            <Card
              email={email}
              clientSecret={clientSecret}
            />
          </Elements>
        </div>
      )}
    </Auth>
  );
};

const Card = ({ clientSecret, email }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();

    setErrorMsg("");
    setIsLoading(true);
    if (!stripe || !elements) {
      return;
    }
    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });

      if (error) {
        setErrorMsg(error.message);
        console.log(error.message);
        setIsLoading(false);
        return;
      }

      const options = {
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/billing/handle-card-addition`,
        data: {
          email,
          paymentMethod: paymentMethod.id,
        },
      };

      const res = await axios.request(options);
      if (res?.status === 200) {
        const apiKey = res.data.apiKey;
        Cookies.set(REACT_APP_KEY_NAME, apiKey, {
          expires: 1,
          path: "/",
          secure: false,
        });
        localStorage.setItem(REACT_APP_KEY_NAME, apiKey);
        window.location.href = "/dashboard";
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setErrorMsg("Something went wrong, Try again");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }
  }, [clientSecret]);

  return (
    <div>
      <h1 className="text-main_text mt-4 mb-6 text-3xl font-[600]">Add a credit card</h1>

      <form
        id="payment-form"
        onSubmit={handleSubmit}
      >
        <CardElement
          options={{
            layout: "tabs",
          }}
          className="w-full p-3 border border-[#D8D8D8] rounded"
        />
        {errorMsg && <p className="mt-4 font-medium text-red-600">{errorMsg}</p>}
        <Button
          width="full"
          type="submit"
          style={{ marginTop: "16px" }}
          disabled={isLoading || !stripe}
        >
          {!isLoading ? "Sign up" : <Loader />}
        </Button>
      </form>
    </div>
  );
};

export default Payment;
