import Button from "../Button";
import { FaArrowLeft } from "react-icons/fa6";
import { CiLocationOn } from "react-icons/ci";
import { CiGlobe } from "react-icons/ci";
import { useHistory } from "react-router-dom";

const ShippingSelect = ({ type, setType, handleSubmit }) => {
  const history = useHistory();

  return (
    <div className="min-w-[320px] w-fit sm:w-[500px] max-w-[500px]">
      <p className="text-xl font-semibold mb-5">How are you shipping?</p>
      <div className="mb-8">
        <div className="flex gap-5">
          <div
            onClick={() => setType({ isDoms: true, isIntl: false })}
            className={`cursor-pointer h-[100px] md:h-[180px] flex flex-col items-center justify-center flex-1 rounded-lg border ${
              type?.isDoms ? "border-[#3C81E9]" : "border-[#0000000D]"
            }`}
          >
            <CiLocationOn size={window.innerWidth > 768 ? 28 : 20} />
            <p className="text-base md:text-xl font-semibold mt-2">Domestic</p>
          </div>
          <div
            onClick={() => setType({ isDoms: false, isIntl: true })}
            className={`cursor-pointer h-[100px] md:h-[180px] flex flex-col items-center justify-center flex-1 rounded-lg border ${
              type?.isIntl ? "border-[#3C81E9]" : "border-[#0000000D]"
            }`}
          >
            <CiGlobe size={window.innerWidth > 768 ? 28 : 20} />
            <p className="text-base md:text-xl font-semibold my-2">International</p>
          </div>
        </div>
      </div>
      <Button
        disabled={!type?.isDoms && !type?.isIntl}
        onClick={handleSubmit}
        width="full"
      >
        Next Step
      </Button>
      <p
        onClick={() => history.push("/dashboard")}
        className="p-4 flex items-center justify-center gap-2 cursor-pointer text-[#666666]"
      >
        <FaArrowLeft fill="#666666" />
        Back
      </p>
    </div>
  );
};

export default ShippingSelect;
